import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastrService, ActiveToast } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class IsBusyService implements OnDestroy {
    private subs = new Subscription();
    private isBusySubscribers: IsBusyValue[] = [];
    private isBusy$ = new BehaviorSubject<boolean>(false);

    constructor(
        private toastr: ToastrService
    ) {
        this.subs.add(
            this.isBusy$.subscribe((value) => {
                const el = document.getElementById("loading-bar-dots");
                if (el) {
                    if (value) {
                        el.classList.add("d-flex");
                        el.classList.remove("d-none");
                    } else {
                        el.classList.add("d-none");
                        el.classList.remove("d-flex");
                    }
                }
            })
        );
    }

    get isBusy() {
        return this.isBusy$.value;
    }

    set(key: string, isBusy: boolean, message?: string, toast?: ActiveToast<unknown>) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        setTimeout(() => {
            const isSubscriberIndex = context.isBusySubscribers.findIndex(e => e.key === key);

            if (isBusy && isSubscriberIndex < 0) {
                if (message) {
                    toast = context.toastr.info(message, "", { timeOut: 0, extendedTimeOut: 0 });
                }

                context.isBusySubscribers.push({
                    key: key,
                    isBusy: isBusy,
                    message: message,
                    toast: toast
                });
            } else if (!isBusy && isSubscriberIndex >= 0) {
                const existing = context.isBusySubscribers[isSubscriberIndex];

                if (existing?.toast) {
                    context.toastr.clear(existing.toast.toastId);
                }

                context.isBusySubscribers.splice(isSubscriberIndex, 1);
            }

            context.isBusy$.next(context.isBusySubscribers.length > 0);
        });
    }

    add(key: string, message?: string, toast?: ActiveToast<unknown>) {
        this.set(key, true, message, toast);
    }

    remove(key: string) {
        this.set(key, false);
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}

export interface IsBusyValue {
    key: string;
    isBusy: boolean;
    message?: string;
    toast?: ActiveToast<unknown>;
}
