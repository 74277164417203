import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkWatcherService {
    public online = new BehaviorSubject<boolean>(true);

    constructor() {
        this.online.next(navigator.onLine);
        window.addEventListener('online', this.onNetworkStatusChange.bind(this));
        window.addEventListener('offline', this.onNetworkStatusChange.bind(this));
    }

    onNetworkStatusChange() {
        this.online.next(navigator.onLine);
        console.log('online ' + this.online.getValue());
    }
}
