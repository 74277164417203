import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, EMPTY, empty, from, Observable, shareReplay, ShareReplayConfig, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { SignOffMatrix } from '../../model/signoffmatrix';
import { HoldUnholdRequest } from '../../model/holdUnholdRequest';
import { DocumentTreeRequest } from '../../model/documentTreeRequest';
import { CommonService } from './common/common.service';
import { ButtonAccessRequest } from '../../model/buttonAccessRequest';
import { SetResetRollforwardRequest } from '../../model/setResetRollforwardRequest';
import { signOffDTO } from '../../model/signOffDTO';
import { GetDocumentMetadata } from '../../model/getMetadata';
import { DocumentTagRequest } from '../../model/documentTagRequest';
import { DocumentTagMetadataRequest } from '../../model/documentTagMetadataRequest';

export type yesNoResponse = "Yes" | "No" | "" | undefined;

@Injectable({
  providedIn: 'root'
})
export class documentsService {
  private readonly url: string = environment.baseUrl + 'documents';

  private readonly tempUrl: string = environment.baseUrl + 'globalportal';

  private documentGuid: string[] = [];

  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  config: ShareReplayConfig = {
    bufferSize: 1,
    refCount: true,
    windowTime: 3000
  };

  getAll(route: string): Observable<any> {
    return this.http.get<any>(`${this.url}/${route}`).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  get(id: any): Observable<any> {
    return this.http.get(`${this.url}/${id}`).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );;
  }

  getUserId(username: string): Observable<any> {
    const params = new HttpParams().set('username', username);
    return this.http.get(`${this.url}` + "/getuserid", { params }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  getUserRole(userid: string): Observable<any> {
    const params = new HttpParams().set('userid', userid);
    return this.http.get(`${this.url}` + "/getuserrole", { params }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }  

  getDocumentsList(route: string, parameters: any): Observable<any> {
    return this.http
      .get(`${this.url}/${route}`, { params: parameters })
      .pipe(
        shareReplay(),
        catchError((error) => {
          console.log(error);
          return throwError(() => error);
        })
    );
  }

  getDeletedDocumentsList(parameters: any): Observable<any> {
    return this.http.get(`${this.url}` + "/deleteddocumentslist", { params: parameters }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  getMetadata(guid: string, type: string, clientId: string, taxpayerId:string): Observable<any> {
    const params = new HttpParams().set('guid', guid).set('type', type).set('clientId', clientId).set('taxpayerId', taxpayerId);
    return this.http.get(`${this.url}/getmetadata`, { params }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }

        //if (error.error) {
        //const errorMessage = error.error.message + ' - ' + error.error.exceptionMessage;
        // Use the throwError operator to throw an error observable
        // return throwError(() => new Error(errorMessage));
        //}

        // If the error format is unexpected, return the original error
        return throwError(() => error);

        //return error;
      })
    );
  }

  getUpdateMetadata(guid: string, type: string, clientId: string, taxpayerId:string): Observable<any> {
    const params = new HttpParams().set('guid', guid).set('type', type).set('clientId', clientId).set('taxpayerId', taxpayerId);
    return this.http.get(`${this.url}/getupdatemetadata`, { params }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  getDocumentsById(documentId: string, clientId: string): Observable<GetDocumentMetadata> {
    const params = new HttpParams().set('documentId', documentId).set('clientId', clientId);
    return this.http.get<GetDocumentMetadata>(`${this.url}/getdocumentsbyid`, { params }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  getDocTypeGroup(engagementId: string): Observable<any> {
    const params = new HttpParams().set('engagementId', engagementId);
    return this.http.get(`${this.url}` + "/getdoctypegroup", { params }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  getRoles(parameters:any): Observable<any> {
    return this.http.get(`${this.url}` + "/getroles", { params: parameters }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  getAssignedRoles(parameters: any): Observable<any> {
    return this.http.get(`${this.url}` + "/getassignedroles", { params: parameters }).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  checkIfTaxpayerHasAssociatedEngagement(taxpayerId: string): Observable<any> {
    const params = new HttpParams().set('taxpayerId', taxpayerId);
    return this.http.get(`${this.url}` + "/checkiftaxpayerhasassociatedengagement", { params }).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }


  signOff(data: signOffDTO) {
    return this.http.post(`${this.url}` + "/signOff", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  removeSignOff(data: any) {
    return this.http.post(`${this.url}` + "/removesignOff", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  deleteRows(data: any) {
    return this.http.post(`${this.url}` + "/deleteRows", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  archiveRows(data: any) {
    return this.http.post(`${this.url}` + "/archiveRows", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  unarchiveRows(data: any) {
    return this.http.post(`${this.url}` + "/unarchiveRows", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  recoverRows(data: any) {
    return this.http.post(`${this.url}` + "/recoverRows", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  create(data: any): Observable<any> {
    return this.http.post(this.url, data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  updateDocuments(data: any): Observable<any> {
    return this.http.post(`${this.url}` + "/updatemetadata", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.url}/${id}`).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  deleteAll(): Observable<any> {
    return this.http.delete(this.url).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  findByTitle(title: any): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}?title=${title}`).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  SendToGlobalPortal(data: SignOffMatrix[]): Observable<any> {
    this.documentGuid = data.map(doc => doc.key);
    return this.http.post(`${this.tempUrl}` + "/sendtoglobalportal", this.documentGuid).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  // Return Doct type group for an engagement
  getEngagementDocTypeGroup(engagementId: string): Observable<any> {
    const params = new HttpParams().set('engagementId', engagementId);
    return this.http.get(`${this.url}/getengagementdoctypegroup`, { params }).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  // Triggers service bus message to put documents on hold.
  holdDocuments(data: HoldUnholdRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/holddocuments", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  // Triggers service bus message to remove documents from hold.
  unholdDocuments(data: HoldUnholdRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/unholddocuments", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  // Triggers service bus message to update Udd.
  uddUpdateForDocuments(data: any): Observable<any> {
    return this.http.post(`${this.url}` + "/updateudd", data).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getDocumentTreeForSelectedNode(request: DocumentTreeRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/getsignoffmatrixdocuments", request).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getWorkflowStatusOfDocuments(keyListSelectedDocs: string[]): Observable<any> {
    const params = new HttpParams().set('keyListSelectedDocs', keyListSelectedDocs.join(','));
    return this.http.get(`${this.url}/getworkflowstatusofdocuments`, { params }).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getEngagementTaxYear(engagementId: string | undefined): Observable<any> {
    if (!engagementId)
      return EMPTY;

    const params = new HttpParams().set('engagementId', engagementId);
    return this.http.get(`${this.url}/getengagementtaxyear`, { params }).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getWorkflowTaxYear(workflowId: string | undefined): Observable<any> {
    if (!workflowId)
      return EMPTY;

    const params = new HttpParams().set('workflowId', workflowId);
    return this.http.get(`${this.url}/getworkflowtaxyear`, { params }).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getAllowDirectDocumentUploadToPYEngagementConfigValue(): Observable<any> {
    return this.http.get(`${this.url}` + "/getallowdirectdocumentuploadtopyengagementconfigvalue", { responseType: 'text' }).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getSelectedEngagementAllowDocumentUploadValue(engagementId: string): Observable<yesNoResponse> {
    const params = new HttpParams().set('engagementId', engagementId);
    /*return this.http.get(`${this.url}` + "/getselectedengagementallowdocumentuploadvalue", { params: params, responseType: 'text' }).pipe(*/
    const req = this.http.get(`${this.url}` + "/getselectedengagementallowdocumentuploadvalue", { params: params, responseType: 'text' }).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );

    const cast = req.toPromise()
      .then(z => {
        return <yesNoResponse>z;
      });

    return from(cast);
  }

  getAllowedButtonActions(request: ButtonAccessRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/getallowedbuttonactions", request).pipe(
      catchError((error) => {
        console.log(error);
        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  // Set rest rollfoward flag on selected documents.
  setResetRollforward(request: SetResetRollforwardRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/setresetrollforward", request).pipe(
      catchError((error) => {
        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  // Checks if workflow is active
  isWorkflowNodeActive(workflowId: any): Observable<any> {
    const params = new HttpParams().set('workflowId', workflowId);
    return this.http.get(`${this.url}` + "/isworkflowactive", { params: params }).pipe(
      catchError((error) => {
        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  // Tag document.
  tagDocument(request: DocumentTagRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/tagdocument", request).pipe(
      catchError((error) => {
        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

  // get document metadata for tagging.
  getDocumentMetadataForTagging(request: DocumentTagMetadataRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/getdocumentmetadatafortagging", request).pipe(
      catchError((error) => {
        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return throwError(() => error);
      })
    );
  }

}
