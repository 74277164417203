import { Injectable, EventEmitter, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Documents } from '../../../model/documents';
import { SignOffMatrix } from '../../../model/signoffmatrix';
import { DocumentTreeRequest } from '../../../model/documentTreeRequest';
import { HttpErrorResponse } from '@angular/common/http';
import { parseChallenges } from '../claim-utils';
import { AccountInfo } from '@azure/msal-browser';
import { addClaimsToStorage } from '../storage-utils';
import { msalConfig, protectedResources } from '../../app.module';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  @Output() nodeClickedSubscription = new EventEmitter<string[]>();
  @Output() nodeClickedEvent = new EventEmitter<TreeNode[]>();
  @Output() aDrawerEvent = new EventEmitter<boolean>();
  @Output() matrixRowSelectEvent = new EventEmitter<SignOffMatrix[]>();
  @Output() signoffOnRowSelectEvent = new EventEmitter<boolean>();
  @Output() rowSelectUnselectEvent = new EventEmitter<boolean>();
  @Output() buttonDisableForDataLoadingEvent = new EventEmitter<boolean>();
  @Output() disableRowSelectOnPanelEvent = new EventEmitter<boolean>();
  @Output() rowArchivedEvent = new EventEmitter<boolean>();
  @Output() refreshTreeOnOperationCompleteEvent = new EventEmitter<boolean>();
  @Output() refreshOnOperationCompleteEvent = new EventEmitter<boolean>();
  @Output() getDocumentTreeOnNodeEvent = new EventEmitter<DocumentTreeRequest>();
  @Output() refreshDocSelectionEvent = new EventEmitter<boolean>();
  @Output() breadcrumbClickedEvent = new EventEmitter<string>();
  @Output() setResetSpinnerEvent = new EventEmitter<boolean>();

  constructor(private authService: MsalService) { }

  clickedNodeToSignOff(msg: string[]) {
    this.nodeClickedSubscription.emit(msg);
  }

  nodeClicked(msg: TreeNode[]) {
    this.nodeClickedEvent.emit(msg);
  }

  refreshDocSelectionOnNodeClick(isClicked: boolean) {
    this.refreshDocSelectionEvent.emit(isClicked);
  }

  getDocumentTreeOnNodeClick(request: DocumentTreeRequest) {
    this.getDocumentTreeOnNodeEvent.emit(request);
  }

  drawerClicked(isDrawerOpened: boolean) {
    this.aDrawerEvent.emit(isDrawerOpened);
  }

  rowSelected(msg: SignOffMatrix[]) {
    this.matrixRowSelectEvent.emit(msg);
  }

  signoffOnRowSelect(value: boolean) {
    this.signoffOnRowSelectEvent.emit(value);
  }

  rowSelectUnselect(value: boolean) {
    this.rowSelectUnselectEvent.emit(value);
  }

  buttonDisableForDataLoading(msg: boolean) {
    this.buttonDisableForDataLoadingEvent.emit(msg);
  }

  disableRowSelectOnPanel(msg: boolean) {
    this.disableRowSelectOnPanelEvent.emit(msg);
  }

  rowArchived(value: boolean) {
    this.rowArchivedEvent.emit(value);
  }

  refreshTreeOnOperationComplete(msg: boolean) {
    this.refreshTreeOnOperationCompleteEvent.emit(msg);
  }

  refreshOnOperationComplete(msg: boolean) {
    this.refreshOnOperationCompleteEvent.emit(msg);
  }

  setResetSpinner(value: boolean) {
    this.setResetSpinnerEvent.emit(value);
  }

  handleClaimsChallenge(response: HttpErrorResponse): void {
    const authenticateHeader: string | null = response.headers.get('WWW-Authenticate');
    const claimsChallengeMap = parseChallenges(authenticateHeader!);
    let account: AccountInfo = this.authService?.instance?.getActiveAccount()!;

    addClaimsToStorage(
      `cc.${msalConfig?.auth?.clientId}.${account?.idTokenClaims?.oid}.${new URL(protectedResources?.profileApi?.endpoint).hostname}`,
      claimsChallengeMap['claims']
    );

    this.authService?.instance?.acquireTokenPopup({
      account: account,
      scopes: protectedResources.profileApi.scopes,
      claims: claimsChallengeMap['claims']
    }).catch((error) => {
      console.log(error);
    });
  }

  breadcrumbClicked(breadcrumbItemId: string) {
    this.breadcrumbClickedEvent.emit(breadcrumbItemId);
  }
}
