enum Buttons {
  CheckIn = "Check-In",
  CheckOut = "Check-Out",
  Archive = "Archive",
  Unarchive = "Unarchive",
  Hold = "Hold",
  Unhold = "Unhold",
  Rollforward = "Rollforward",
  RemoveRollforward = "Remove Rollforward",
  TagDocument = "Tag Document",  
}

export { Buttons };
