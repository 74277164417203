/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Predicate, EntityQuery, FetchStrategy } from "breeze-client";
import { EntityManagerProviderService } from "../entity-manager-provider/entity-manager-provider.service";
import * as model from '../../../model/model';
import * as modelDto from '../../../model/modelDto';
import * as modelEnums from '../../../model/modelEnums';
import { BreezeOptions, BreezeResult, inlineCountResult, instanceOfInlineCountQueryOptions, QueryOptions } from '../../services/utilities';

@Injectable({
	providedIn: 'root'
})
export class BridgeService {
	constructor(private _http: HttpClient, private _entityManagerProvider: EntityManagerProviderService) { }

	private isEmpty(obj: Record<string, unknown>): boolean {
		for (const prop in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(prop)) {
				return false;
			}
		}

		return true;
	}

	private httpPost<O extends BreezeOptions, T>(resourceName: string, params: Record<string, any>, options?: O) {
		let query = EntityQuery
			.from(resourceName)
			.withParameters({ 
				$method: 'POST',
				$encoding: 'JSON',
				$data: params
			});

		return this.executeQuery<O, T>(query, options);
	}

	private httpGet<O extends BreezeOptions, T>(resourceName: string, params: Record<string, unknown>, options?: O) {
		let query = EntityQuery
			.from(resourceName);

		if (!this.isEmpty(params)) {
			query = query.withParameters(params);
		}

		return this.executeQuery<O, T>(query, options);
	}

	private postWithParams<O extends BreezeOptions, T>(resourceName: string, params: Record<string, unknown>, options: O) {
		const postParams = { $method: 'POST', $encoding: 'JSON', $data: params };
		return this.getWithParams<O, T>(resourceName, postParams, options);
	}

	private getWithParams<O extends BreezeOptions, T>(resourceName: string, params: Record<string, unknown>, options: O) {
		const query = EntityQuery
			.from(resourceName)
			.withParameters(params);
		return this.executeQuery<O, T>(query, options);
	}

	private executeQuery<O extends BreezeOptions, T>(query: EntityQuery, options?: O): Promise<BreezeResult<O, T>> {
        if (options) {
            const inlineCount = instanceOfInlineCountQueryOptions(options)
                ? options.inlineCount
                : false;

            if (options.filter) {
                query = query.where(options.filter);
            }

            if (options.sort) {
                query = query.orderBy(options.sort);
            }
            else {
                if (inlineCount && typeof options.take === "number" && options.take > 0) {
                    throw Error("Oops! Inline count won't work unless you provide a 'sort' column or take 0.");
                }
            }

            if (Array.isArray(options.include) && options.include.length) {
                query = query.expand(options.include);
            }

            if (inlineCount) {
                query = query.inlineCount(inlineCount);
            }

            if (options.noTracking) {
                query = query.noTracking(options.noTracking);
            }

            if (Array.isArray(options.select) && options.select.length) {
                query = query.select(options.select);
            }

            if (typeof options.skip === "number" && options.skip >= 0) {
                query = query.skip(options.skip);
            }

            if (typeof options.take === "number" && options.take >= 0) {
                query = query.take(options.take);
            }

            if (typeof options.top === "number" && options.top >= 0) {
                query = query.top(options.top);
            }
        }
		
         // eslint-disable-next-line
         return this._entityManagerProvider.manager()
            .executeQuery(query.using(FetchStrategy.FromServer))
            .then((data) => {
                if (data?.inlineCount != null) {
                    // eslint-disable-next-line
                    return new inlineCountResult(<T[]><any>data.results, data.inlineCount) as any;
                }
				
				if (options && options.take === 1) {
					if (Array.isArray(data?.results)) {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-return
						return data.results[0];
					}
				}
                
                // eslint-disable-next-line
                return <T[]><any>data.results as any;
            });
	}

	lookups<O extends BreezeOptions = QueryOptions>( options?: O) {
		const params = {  };
		return this.httpGet<O, any>('lookups', params, options);
	}
		
}
