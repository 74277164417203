import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Config from '../config';
import { ReplaySubject } from 'rxjs';
import { ServerSideConfigData } from '../../../typings';
import invariant from '../tiny-invariant';

@Injectable({
    providedIn: 'root'
})
export class ServerSideConfigDataService {
    configData: ServerSideConfigData | undefined;
    configDataLoaded$ = new ReplaySubject<boolean>(1);

    constructor(private http: HttpClient) { }

    async loadServerSideData(): Promise<boolean> {
        if (!this.configData) {
            try {
                const result = await this.http.get<ServerSideConfigData>(`${Config.serviceName}/getServerSideConfigData`)
                    .toPromise();
                this.configData = result;

                invariant(result?.hashes[0]);
                Config.hashes.currentMetadataHash = result.hashes[0].metadataHash;
                Config.hashes.currentAnnotatedMetadataHash = result.hashes[0].annotatedMetadataHash;
                Config.hashes.currentLookupsHash = result.hashes[0].lookupsHash;

                this.configDataLoaded$.next(true);
                return true;
            } catch (err) {
                return await Promise.reject(err);
            }
        } else {
            return true;
        }
    }
}
