export enum Constant {
  context = 0,
  userid = 1,
  taxpayerLevelDocumentTypeGroup = "Taxpayer Level Document Type Group",
  taxpayerLevelDocumentTypePending = " Taxpayer Level Document Type Pending",
  taxpayerLevelDocumentTypePermanent = "Taxpayer Level Document Type Permanent",
  //taxpayerLevelDocConfig = [""],
  //signer = 'Signer',
  //mcs_signer = 'mcs_signer'
}
