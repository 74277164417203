import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatdate'
})

export class FormatDate implements PipeTransform {

  transform(originaldate: any) {

    if (!originaldate) {
      return '';
    }

    const date = originaldate ? new Date(originaldate) : null;
    const formattedDate = date?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }) || '';

    return `${formattedDate}`;
  }

}
