<!-- <div [hidden]="net.online | async">Offline</div> -->
<!-- <h1>Hello, {{name | async}}!</h1> -->
<!-- <p>Welcome to your new single-page <a href="https://developers.google.com/web/progressive-web-apps">Progressive Web Application</a>, built with:</p>
<ul>
    <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
    <li><a href='https://angular.io/'>Angular</a> and <a href='http://www.typescriptlang.org/'>TypeScript</a> for client-side code</li>
    <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
    <li><a href='https://angular.io/guide/service-worker-intro'>Service-Workers</a> for offline access and installability on the desktop/home-screen</li>
</ul>
<p>To help you get started, we've also set up:</p>
<ul>
    <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
    <li><strong>Angular CLI integration</strong>. In development mode, there's no need to run <code>ng serve</code>. It runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
    <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration automatically invokes <code>ng build</code> to produce minified, ahead-of-time compiled JavaScript files.</li>
</ul>
<p>The <code>ClientApp</code> subdirectory is a standard Angular CLI application. If you open a command prompt in that directory, you can run any <code>ng</code> command (e.g., <code>ng test</code>), or use <code>npm</code> to install extra packages into it.</p>
<p>If you want to enable server-side prerendering, see the steps in <code>Startup.cs</code>.</p> -->

<!-- <div class="jwt-block">
    <span class="token-title">MSAL Account / Profile</span>
    <button class="btn btn-link" title="Copy to Clipboard" (click)="copyToClipboard('msal')">
        <i class="fa fa-clipboard" aria-hidden="true"></i>
    </button>
</div> -->
<!-- <code>{{msalAccount | async}}</code> -->


<mat-drawer-container class="example-container" autosize [hasBackdrop]="false">

  <mat-drawer #drawer [opened]="isDrawerOpened" class="example-sidenav treecontainer" mode="side">
    <!--  <p-button icon="pi pi-angle-double-left" (click)="drawer.toggle()"
    styleClass="drawerBtn text-dark"></p-button>-->
    <div class="">
      <app-treeview></app-treeview>
    </div>
  </mat-drawer>

  <div class="example-sidenav-content">
    <div class="col-12">
      <app-docmanagement></app-docmanagement>
    </div>
  </div>

</mat-drawer-container>
