import { Component } from '@angular/core';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { ToastrService } from 'ngx-toastr';
import { NetworkWatcherService } from '../../services/network-watcher/network-watcher.service';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject } from 'rxjs';
import { Profile } from '../../../typings';
import { CommonService } from 'src/app/services/common/common.service';
@Component({
  standalone: false,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent {
  name: BehaviorSubject<string> = new BehaviorSubject<string>("");
  msalAccount: BehaviorSubject<string> = new BehaviorSubject<string>("");

  public isDrawerOpened = true;

  constructor(
    private msalService: MsalService,
    private ai: ApplicationInsightsService,
    private toastr: ToastrService,
    public net: NetworkWatcherService,
    private commonService: CommonService
  ) {

    const account: Profile | undefined = this.msalService.instance.getAllAccounts()[0];
    if (account) {
      this.name.next(account?.idTokenClaims?.name ?? "");
      this.msalAccount.next(JSON.stringify(account, null, '\t'));
    }

    ai.logPageView();
  }

  private tokenToCopy: string | undefined;
  private copyCmd(e: ClipboardEvent) {
    e.clipboardData?.setData('text/plain', (this.tokenToCopy ?? ""));
    e.preventDefault();
    // eslint-disable-next-line
    document.removeEventListener('copy', null as any);
  }

  copyToClipboard(token: string) {
    if (token === "msal") {
      this.tokenToCopy = this.msalAccount.value;
    } else {
      this.tokenToCopy = undefined;
      return;
    }

    document.addEventListener('copy', this.copyCmd.bind(this));
    document.execCommand('copy');
    document.removeEventListener('copy', this.copyCmd.bind(this));

    this.toastr.success(`${token} Token copied to your clipboard`);
  }

  ngOnInit() {

    this.commonService.aDrawerEvent
      .subscribe((data: boolean) => {
        console.log('From component: ' + data);
        this.isDrawerOpened = data;
      });

  }
}
