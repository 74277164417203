import { Injectable, OnDestroy } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { ServerSideConfigDataService } from '../server-side-config-data/server-side-config-data.service';
import { Profile } from '../../../typings';
import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService implements OnDestroy {
    private env: { [name: string]: string } = {};
    private subs = new Subscription();

    constructor(
        private serverSide: ServerSideConfigDataService,
        private msalService: MsalService,
    ) {
        this.subs.add(
            this.serverSide.configDataLoaded$.subscribe(loaded => {
                if (loaded && this.serverSide.configData) {
                    // eslint-disable-next-line no-undef
                    const config: Microsoft.ApplicationInsights.IConfig = {
                        instrumentationKey: this.serverSide.configData.appInsightsKey
                    };

                    const envProps = (this.serverSide.configData.appInsightsEnv || '').split(';');
                    envProps.forEach(p => {
                        const e: string[] = p.split(':');
                        if (e.length > 2) {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          this.env[e[0]!] = e[1] ?? "";
                        }
                    });

                    if (!AppInsights.config) {
                      AppInsights.downloadAndSetup?.(config);
                    }

                    const employeeId = (this.msalService.instance.getAllAccounts()[0] as Profile).idTokenClaims?.employeeID;
                    if (employeeId) {
                        AppInsights.setAuthenticatedUserContext(employeeId);
                    }
                }
            })
        );
    }

    private getProps(properties?: { [name: string]: string }) {
        const props = this.env;
        if (properties) {
            for (const p in properties) {
                // eslint-disable-next-line no-prototype-builtins
                if (properties.hasOwnProperty(p)) {
                    props[p] = properties[p] ?? "";
                }
            }
        }

        return props;
    }

    logPageView(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }, duration?: number) {
        AppInsights.trackPageView(name, url, this.getProps(properties), measurements, duration);
    }

    logEvent(name: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        AppInsights.trackEvent(name, this.getProps(properties), measurements);
    }

    // eslint-disable-next-line no-undef
    logError(exception: Error, handledAt?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }, severityLevel?: AI.SeverityLevel) {
        AppInsights.trackException(exception, handledAt, this.getProps(properties), measurements, severityLevel);
    }

    // eslint-disable-next-line no-undef
    logTrace(message: string, properties?: { [name: string]: string }, severityLevel?: AI.SeverityLevel) {
        AppInsights.trackTrace(message, this.getProps(properties), severityLevel);
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
}
