import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncatestring'
})
export class TruncateStringPipe implements PipeTransform {
  transform(value: string, limit = 20, completeWords = false, ellipsis = '...') {
    if (!value) {
      return value; 
    }

    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }

    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
