import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private readonly url: string = environment.baseUrl + 'config';

  constructor(private http: HttpClient) { }

  // Get SignOff matrix row count.
  getSignOffMatrixRowCount(): Observable<any> {
    return this.http.get(`${this.url}/getsignoffmatrixrowcount`).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  // Get appsettings configuration values.
  getAppSettingsConfiguration(): Observable<any> {
    return this.http.get(`${this.url}/getappsettingsconfiguration`).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }
}
