import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import invariant from "./app/services/tiny-invariant";


export function getBaseUrl() {
	const el = document.getElementsByTagName("base")[0];
	invariant(el);

	return el.href;
}

const providers = [
	{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
  ];

if (environment.production) {
	enableProdMode();
  }
  
  platformBrowserDynamic(providers).bootstrapModule(AppModule)
	.catch(err => console.log(err));
