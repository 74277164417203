import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SaveOptions, SaveResult, EntityChangedEventArgs } from 'breeze-client';
import { Repository } from '../repository';
import { BridgeService } from '../bridge/bridge.service';
import { EntityManagerProviderService } from '../entity-manager-provider/entity-manager-provider.service';
import { Employee } from '../../../model/model';
import { ToastrService } from 'ngx-toastr';
import { BreezeQueuedSaveFailedError, GlobalErrorHandler } from '../../services/global-error-handler';
import { MetaEntity } from '../../../model/metaEntity';
import Strings from '../strings';
import { BreezeOptions, QueryOptions } from "../utilities";

@Injectable({
	providedIn: 'root'
})
export class UnitOfWorkService implements OnDestroy {
	hasChanges = new BehaviorSubject(false);
	entityChanged = new Subject<EntityChangedEventArgs>();

	// Declare repositories for entity types you need to create/read/update/delete
	employees: Repository<Employee> | undefined;

	private initialized = false;

	constructor(
		private bridge: BridgeService,
		private provider: EntityManagerProviderService,
		private toastr: ToastrService,
		private errorHandler: GlobalErrorHandler
	) { }

	init() {
		if (this.initialized) {
			return;
		}

		this.provider.manager().hasChangesChanged.subscribe((evt) => {
			this.hasChanges.next(evt.hasChanges);
		});

		this.provider.manager().entityChanged.subscribe((evt) => {
			this.entityChanged.next(evt);
		});

		// Initialize the above repositories
		this.employees = this.createRepo('Employee', 'employees');

		this.initialized = true;
	}

	private createRepo<T extends MetaEntity>(entityTypeName: string, resourceName: string) {
		return new Repository<T>(this.provider, this.toastr, this.errorHandler, entityTypeName, resourceName);
	}

	async commit(successMessage: string | false = Strings.toasts.savedData) {
	  const saveOptions = new SaveOptions({ resourceName: "savechanges" });
  
	  return this.provider
		.manager()
		.saveChanges(null, saveOptions)
		.then((saveResult: SaveResult) => {
		  if (successMessage) {
			this.toastr.success(successMessage);
		  }
  
		  return true;
		})
		.catch((error: BreezeQueuedSaveFailedError) => {
		  // Http request errors are handled in add-authorization-header-interceptor.ts
		  if (!error.innerError.httpResponse) {
			this.errorHandler.handleError(error);
		  }
  
		  return false;
		});
	}

	rollback() {
		this.provider.manager().rejectChanges();
	}

	ngOnDestroy() {
		if (this.hasChanges) {
			this.hasChanges.unsubscribe();
		}

		if (this.entityChanged) {
			this.entityChanged.unsubscribe();
		}
	}
}
