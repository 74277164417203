import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changetoinitials'
})

export class ChangeToInitials implements PipeTransform {
  transform(columnName: any, columnDate: any) {

    if (!columnName) {
      return '';
    }

    const firstLastName = columnName.split(' ');
    const firstLetter = firstLastName[0].charAt(0).toUpperCase();
    const lastLetter = firstLastName[1]?.charAt(0).toUpperCase() || '';
    const initials = `${firstLetter}${lastLetter}`;

    const signOffDate = columnDate ? new Date(columnDate) : null;
    const formattedSignOffDate = signOffDate?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }) || '';
    
    return `${initials} ${formattedSignOffDate}`;
  }

}
