enum Entities {
  mcs_document,
  mcs_doctypemasterdata,
  mcs_doctypegroupmasterdata,
  mcs_doctypedescmasterdata,
  mcs_doctype2nddescmasterdata,
  account,
  contact,
  mcs_engagement,
  msdyn_project,
  mcs_docdesctodttodtgmapping,
  mcs_doctypetodtgmapping,
  mcs_secdesctoddtodttodtgmapping
}

export { Entities };
