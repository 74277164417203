import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
//import { BreezeSetupGuard } from '../breeze-setup/breeze-setup.guard';    Commented out to bypass the DB.

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate {
    constructor(
      private msalGuard: MsalGuard,
        //private breezeGuard: BreezeSetupGuard
    ) { }

    private reroute() {
        return false;
    }

    public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      this.fix94210(state);
      if (await this.msalGuard.canActivate(next, state).toPromise().catch(() => this.reroute()) === false) {
          return this.reroute();
      }

      //if (await this.breezeGuard.canActivate(next, state).catch(() => this.reroute()) === false) {
      //    return this.reroute();
      //}

      return true;
    }

  //Required for MSAL redirect to have the correct URL because "angular knows better"
  // see: https://stackoverflow.com/a/18251730/2489966
  // Could not locate any notion of a configuration setting to correct for this
  // fix for https://bdousa.visualstudio.com/TaxPMA/_workitems/edit/94210
  // Presents as an instantaneous redirect loop because route differs between current %27 and target ' in URL
  // Tested against a client name of !@$#%^&*()_+-=,./<>?;':"[]\{}|`~
  private fix94210(state: RouterStateSnapshot) {
    state.url = state.url.replace(/'/g, "%27");
  }
}
