import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class uploadService {
  //application/json
  //application/x-www-form-urlencoded
  private readonly url: string = environment.baseUrl + 'upload';
  private headers = new HttpHeaders()
    //.set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', '*');
  constructor(private http: HttpClient) {
  }

  create(document: any): Observable<any> {
    return this.http.post(`${this.url}`, document).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }
}
