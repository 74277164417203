import { Dropdown } from '../model/dropdown';

export class GetDocumentMetadata {
  taxpayers: Dropdown[] = [];
  taxpayerId: string | undefined;
  selectedTaxpayer: Dropdown | undefined;
  engagements: Dropdown[] = [];
  engagementId: string | undefined;
  selectedEngagement: Dropdown | undefined;
  workflows: Dropdown[] = [];
  workflowId: string | undefined;
  selectedWorkflow: Dropdown | undefined;
  doctypes: Dropdown[] = [];
  doctypeId: string | undefined;
  selectedDoctype: Dropdown | undefined;
  doctypeGroups: Dropdown[] = [];
  doctypeGroupId: string | undefined;
  selectedDoctypeGroup: Dropdown | undefined;
  doctypeDescs: Dropdown[] = [];
  doctypeDescId: string | undefined;
  selectedDoctypeDesc: Dropdown | undefined;
  doctype2ndDescs: Dropdown[] = [];
  doctype2ndDescId: string | undefined;
  selectedDoctype2ndDesc: Dropdown | undefined;
  userDefineDescription: string | undefined;
  applicableTaxYear: string | undefined;
  engagementTaxYear: string | undefined;
  associatedTaxpayers: Dropdown[] = [];
  associatedWorkflows: Dropdown[] = [];
  associations: Dropdown[] = [];

  // The workflow tax year for the document
  taxYear: string | undefined;
}
