import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Context } from '../model/context';
import { Constant } from '../model/constant.enums';
import { LocalService } from './services/local.service';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import Utilities from './services/utilities';
import { Subscription } from 'rxjs';
import { TimeOutService } from './services/time-out.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  title = 'app';
  private readonly _destroying$ = new Subject<void>();

  isIframe = false;
  loginDisplay = false;

  private context!: Context;
  recId: string | any;
  recType: string | any;
  clientId: string | any;
  clientName: string | any;
  type: string | any;
  accessToken: string | undefined;
  activeAccount: any | undefined;
  customsParams = {
    recid: 'fca5f128-04e5-ed11-a7c7-00224828da82', rectype: 'contact', clientid: 'c60cee38-03e5-ed11-a7c7-6045bdee1bea',
    clientname: 'Frank%20and%20Tonya%20Smith'
  };

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private localService: LocalService, private msalService: MsalService
    , @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService, private timeOutService: TimeOutService) {
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.getContext();
    this.setLoginDisplay();

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        //this.login();
        Utilities.hideLoadingScreen();
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
    this.timeOutService.startMonitoringUserActivity();
  }

  checkAndSetActiveAccount() {

    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }


    //if (this.msalGuardConfig.authRequest && this.loginDisplay) {
    //  this.msalService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as SilentRequest)
    //    .subscribe((response: AuthenticationResult) => {
    //      this.msalService.instance.setActiveAccount(response.account);
    //      this.activeAccount = response.account;
    //      console.log(response);
    //      this.getContext();
    //    });
    //} else {
    //  this.msalService.loginRedirect();
    //}   
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private getContext() {
    console.log(window.location.href);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.activatedRoute.queryParams.subscribe((params: any) => {
          if (params && Object.keys(params).length > 0) {
            this.context = {
              recId: params['recid'],
              recType: params['rectype'],
              clientId: params['clientid'],
              clientName: params['clientname'],
              type: this.getTypeFromRecType(this.recType),
              owner: ''
            }
            this.localService.saveData(Constant[Constant.context], JSON.stringify(this.context));
          }
          else if (this.router.url.length == 1 && window.location.href.includes('localhost')) {
            const updatedParams = { ...params, ...this.customsParams };
            this.router.navigate([], { queryParams: updatedParams });
          }
          else {
            if (this.router.url.length == 1) {
              this.localService.removeData(Constant[Constant.context]);
              if (!this.context || !this.context.recId || !this.context.recType || !this.context.clientId || !this.context.clientName) {
                this.router.navigate(['/contexterror']);
              }
            }
          }
          //this.login();
        })
      }
    });
  }

  getTypeFromRecType(rectype: string):string {
    let type = "";
    if (rectype == 'account') {
      type = 'client';
    }
    else if (rectype == 'contact') {
      type = 'taxpayer';
    }
    else if (rectype == 'taxyear') {
      type = 'taxyear';
    }
    else if (rectype == 'mcs_engagement') {
      type = 'engagement';
    }
    else if (rectype == 'msdyn_project') {
      type = 'workflow';
    }
    return type;
  }

}
