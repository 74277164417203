<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Missing Context Notice</title>
  <style>
    body {
      background-color: #222;
      color: #fff;
      font-family: sans-serif;
      margin: 0;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
    }

    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;
    }

    a {
      color: #337ab7;
      text-decoration: none;
    }

      a:hover {
        text-decoration: underline;
      }
  </style>
</head>
<body>
  <h1><b>Missing Context Notice</b></h1>
  <p>
    We’re sorry, but we are unable to proceed due to an expired session or an unexpected transient event.
    To resolve this issue, please navigate to <b>BDO Tax UserVerse and re-launch Document Management.</b> This will ensure that the proper context is established for your session.
    We apologize for any inconvenience and appreciate your understanding. If you continue to experience non-timeout issues, please submit a MySupport ticket for further assistance.
  </p>
</body>
</html>
