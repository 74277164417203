import * as moment from 'moment';
import { NamingConvention, DataType, DataService } from 'breeze-client';

export default class Config {
    static appTitle = "Tax UserVerse";
    static helpLink = "TODO";
    static virtualDirRoot = window.location.origin + "/";
    static configApi = Config.virtualDirRoot + 'api/config';
    static serviceName = Config.virtualDirRoot + 'breeze';
    static storeExpirationMs = (1000 * 60 * 60 * 24 * 30); // 30 days
    static dayMs = (1000 * 60 * 60 * 24); // 1 day
    static throttleMs = 200;
    static toastrTimeoutMs = 4000; // 4 seconds
    static skipFieldsForMessage = ["rights", "createdDate", "id", "createdByUserId", "modifiedDate", "modifiedByUserId", "relatedRecordId", "sortOrder"];

    static params = window.location.search;
    static openIdConfigUri: string | undefined;
    static redirectUrl: string | undefined;
    static resource: string | null | undefined;

    static stateKeys = {
        metadataHash: Config.appTitle + '-V2-metadataHash',
        annotatedMetadataHash: Config.appTitle + '-V2-annotatedMetadataHash',
        lookupsHash: Config.appTitle + '-V2-lookupsHash',
        breezeMetadata: Config.appTitle + '-V2-breezeMetadata',
        lookups: Config.appTitle + '-V2-lookups',
        annotatedMetadata: Config.appTitle + '-V2-annotatedMetadata',
        savedRoute: Config.appTitle + '-V2-savedRoute'
    };

    static hashes = {
        currentMetadataHash: '',
        currentAnnotatedMetadataHash: '',
        currentLookupsHash: ''
    };

    private static queryParam(name: string): string | null {
        const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        } else {
            return results[1] ? decodeURI(results[1]) || null : null;
        }
    }

    private static hasQueryParam(name: string): boolean {
        return !!this.queryParam(name);
    }

    private static configureImpersonate(): void {
        this.openIdConfigUri = Config.params.includes("e2e") ? Config.virtualDirRoot + '.well-known/openid-configuration-alt' : Config.configApi + '/.well-known/openid-configuration';
        this.redirectUrl = Config.virtualDirRoot + "signin-oidc" + (Config.params.includes("e2e") ? "?e2e" : "");
        this.resource = this.hasQueryParam("impersonate") ? this.queryParam("impersonate") + ":" + this.queryParam("code") : null;
    }

    static init() {
        this.configureImpersonate();
        NamingConvention.camelCase.setAsDefault();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        DataType.parseDateFromServer = (source) => moment(source).toDate();

        /*
        (<any>breeze.EntityType.prototype).getEntityCtor = function () {
            var origCtor = this.getEntityCtor.apply(this);

            var rightsProp = this.getDataProperty("rights");
            if (!rightsProp) {
                this.addProperty(new breeze.DataProperty(this.rights));
                rightsProp = this.getDataProperty("rights");
            }

            if (rightsProp.isUnmapped) {
                rightsProp.isUnmapped = false;
            }

            return origCtor;
        };
        */

        // Breeze does a bad thing and uses substr(-1) instead of slice(-1)
        // that is browser inconsistent.  This implementation overrides the
        // default behavior
        // eslint-disable-next-line
        (<any>DataService)._normalizeServiceName = function (serviceName: string) {
            serviceName = serviceName.trim();
            if (serviceName.slice(-1) !== "/") {
                return serviceName + '/';
            } else {
                return serviceName;
            }
        };
    }
}
