import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGuard } from "./guards/app-guard/app.guard";
import { HomeComponent } from './home/home/home.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { ContextMissingComponent } from './error-pages/context-missing/context-missing.component';
import { InternalServerComponent } from './error-pages/internal-server/internal-server.component';
import { RecoverComponent } from './recover/recover.component';
import { TimeOutComponent } from './error-pages/time-out/time-out.component';

export const routes: Routes = [
  // {
  // 	path: "",
  // 	canActivate: [AppGuard],
  // 	children: [
  // 		{
  // 			path: "",
  // 			loadChildren: () => import("./home/routes"),
  // 			pathMatch: "full",
  // 		},
  // 	],
  // },
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AppGuard] },
  { path: 'auth', component: MsalRedirectComponent },
  { path: 'contexterror', component: ContextMissingComponent },
  { path: 'internalerror', component: InternalServerComponent },
  { path: 'timeout', component: TimeOutComponent },
  { path: 'recover', component: RecoverComponent, canActivate: [AppGuard] },
  {
    path: "",
    loadChildren: () => import("./error-pages/routes"),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule{};
