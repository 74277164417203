<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark border-bottom box-shadow bg-header">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/']">
        <img src="../../assets/Tax-UserVerse_mark_white 1.png" width="147" height="35" class="d-inline-block align-top" alt="">
      </a>
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="vr bg-white"></div>
      <nav class="navbar navbar-light">
        <span class="navbar-brand mb-0 h1 px-3">Document Management</span>
      </nav>
      <div class="navbar-collapse collapse d-sm-inline-flex justify-content-end" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }">
            <!--<button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>
            <button mat-raised-button color="accent" *ngIf="loginDisplay" (click)="logout()">Logout</button>-->

            <a class="nav-link text-light" [routerLink]="['/']"> <i class="pi pi-user userIcons"></i> <b> {{activeAccount?.name}} </b></a>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</header>
