import { EntityType, MetadataStore, Predicate, EntityQuery, EntityState, EntityManager, EntityKey } from "breeze-client";
import { BehaviorSubject } from "rxjs";
import { EntityManagerProviderService } from "./entity-manager-provider/entity-manager-provider.service";
import { MetaEntity } from '../../model/metaEntity';
import { ToastrService } from 'ngx-toastr';
import { BreezeQueuedSaveFailedError, GlobalErrorHandler } from '../services/global-error-handler';
import Strings from './strings';
import { MergeableDto } from "../../typings";
import invariant from "./tiny-invariant";

declare global {
    interface Window {
        "$ms": MetadataStore | undefined;
    }
}

export class Repository<T extends MetaEntity> {
    hasChanges = new BehaviorSubject(false);

    constructor(
        private entityManagerProvider: EntityManagerProviderService,
        private toastr: ToastrService,
        private errorHandler: GlobalErrorHandler,
        private entityTypeName: string,
        private resourceName: string
    ) {
        let entityType: EntityType;
        if (this.entityTypeName) {
            entityType = <EntityType>this.getMetastore().getEntityType(this.entityTypeName);
            entityType.setProperties({ defaultResourceName: this.resourceName });

            this.getMetastore().setEntityTypeForResourceName(this.resourceName, this.entityTypeName);
        }

        this.manager().hasChangesChanged.subscribe(this.updateHasChanges);

        if (!window.$ms) {
            window.$ms = <MetadataStore>this.manager().metadataStore;
        }
    }

    withId(key: string): Promise<T> {
        invariant(this.entityTypeName, "Repository must be created with an entity type specified");

        return this.manager().fetchEntityByKey(this.entityTypeName, key, true)
            .then(function (data) {
                invariant(data.entity, "Entity not found!");
                return <T>data.entity;
            });
    }

    findInCache(predicate: Predicate, sort?: string): T[] {
        let query = EntityQuery
            .from(this.resourceName)
            .where(predicate);

        if (sort) {
            query = query.orderBy(sort);
        }

        return this.executeCacheQuery(query);
    }

    allCached(sort?: string): T[] {
        let query = EntityQuery
            .from(this.resourceName);

        if (sort) {
            query = query.orderBy(sort);
        }

        return this.executeCacheQuery(query);
    }

    updateHasChanges = (eventArgs?: unknown) => {
        if (this.entityTypeName) {
            this.hasChanges.next(this.manager().hasChanges([this.entityTypeName]));
        }
    }

    cancelChanges(entity?: T): void {
        let entities: T[];
        const manager = this.manager();

        if (entity) {
            entities = [entity];
        } else {
            entities = this.allCached();
        }

        entities.forEach(function (e) {
            if (e.entityAspect.entityState === EntityState.Added) {
                manager.detachEntity(e);
            } else {
                e.entityAspect.rejectChanges();
            }
        });

        this.updateHasChanges();
    }

    executeCacheQuery(query: EntityQuery): T[] {
        return <T[]>this.entityManagerProvider.manager()
            .executeQueryLocally(query);
    }

    getMetastore(): MetadataStore {
        return this.manager().metadataStore;
    }

    private manager(): EntityManager {
        return this.entityManagerProvider.manager();
    }

    createEntity(config?: Record<string, unknown>): T {
        const e = <T>this.manager().createEntity(this.entityTypeName, config);

        // eslint-disable-next-line
        if ((<any>e).createdDate != null) {
            // eslint-disable-next-line
            (<any>e).createdDate = new Date().getTime();
        }

        // eslint-disable-next-line
        if ((<any>e).createdByUserId != null) {
            // eslint-disable-next-line
            (<any>e).createdByUserId = "client";
        }

        return e;
    }

    addEntity(entity: T): T {
        const added = this.manager().addEntity(entity);
        this.updateHasChanges();
        return <T>added;
    }

    saveChanges(entities?: T[]): Promise<void> {
        let modified: T[];

        if (entities) {
            modified = entities;
        } else {
            modified = <T[]>this.manager().getChanges(this.entityTypeName).filter((entity) => entity.entityAspect.entityState === EntityState.Added || entity.entityAspect.entityState === EntityState.Deleted || entity.entityAspect.entityState === EntityState.Modified);
        }

        modified.forEach((entity) => {
            // eslint-disable-next-line
            (<any>entity.entityAspect).fieldsSaving = {};
            // eslint-disable-next-line
            (<any>entity.entityAspect).fieldsSavingEntityState = entity.entityAspect.entityState;

            for (const prop in entity.entityAspect.originalValues) {
                // eslint-disable-next-line
                if (entity.entityAspect.originalValues.hasOwnProperty(prop)) {
                    // eslint-disable-next-line
                    (<any>entity.entityAspect).fieldsSaving[prop] = (entity.entityAspect.originalValues as any)[prop];
                }
            }
        });

        return this.manager()
            .saveChanges(modified)
            .then((saveResult) => {
                this.updateHasChanges();

                this.toastr.success(Strings.toasts.savedData);
            })
            .catch((error: BreezeQueuedSaveFailedError) => {
                // Http request errors are handled in add-authorization-header-interceptor.ts
                if (!error.innerError.httpResponse) {
                    this.errorHandler.handleError(error);
                }
            });
    }

    mergeEntityIntoCache(data: { results: MergeableDto[] }, isPartial?: boolean, entityCallback?: (entity: T, dto: MergeableDto) => void): T[] {
        const entityType = <EntityType>this.getMetastore().getEntityType(this.entityTypeName);
        const manager = this.manager();

        return data.results.map((dto) => {
            const id = dto.id;
            const key = new EntityKey(entityType, id);
            let entity = <T>manager.getEntityByKey(key);
            if (!entity) {
                entity = <T>entityType.createEntity();
                entity.id = id;
                manager.attachEntity(entity);
                entity.isPartial = isPartial === undefined ? true : isPartial;
            }

            this.mapToEntity(entity, dto);

            if (entityCallback) {
                entityCallback(entity, dto);
            }

            entity.entityAspect.setUnchanged();

            return entity;
        });
    }

    private mapToEntity(entity: T, dto: MergeableDto): MetaEntity {
        for (const prop in dto) {
            // eslint-disable-next-line
            if (entity.hasOwnProperty(prop)) {
                const propInfo = entity.entityType.getProperty(prop, false);
                if (!propInfo || (propInfo && !propInfo.isNavigationProperty)) {
                    // eslint-disable-next-line
                    (entity as any)[prop] = (dto as any)[prop];
                }
            }
        }

        return entity;
    }
}
