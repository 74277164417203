<div class="docmanagement-container">
  <p-fileUpload #fileUpload name="files[]" draggable="true"
                [showUploadButton]="false" [showCancelButton]="false"
                [multiple]="true" [maxFileSize]="2.0000E+9" [fileLimit]="10"
                (onSelect)="onDocumentsDrop($event)"
                [customUpload]="true" emptyTemplate="No files selected">
    <ng-template pTemplate="content">
      <div class="row ms-1">
        <div class="col-10">
          <div class="">
            <p-breadcrumb class="max-w-full" [home]="home" [model]="breadcumItems" (onItemClick)="breadcrumbItemClicked($event)"></p-breadcrumb>
          </div>
        </div>
        <div *ngIf="isUploadAllowed && clientValidation" class="col-2 p-0 text-end">
          <button mat-flat-button (click)="onUploadDocumentsButtonClick()" [disabled]="uploadSidebarDisabled" style="top:2px">
            <i class="pi pi-upload" style="color: darkblue ; font-size:0.8rem"></i>
            Upload Document (s)
          </button>
        </div>
      </div>

      <div class="row ms-1 mb-1">
        <div class="col-7">
          <div class="row">
            <div class="col-12 mt-1" style="display:inline-flex;">
              <p-selectButton styleClass="p" [options]="options" (onChange)="drawerHandler($event)" [(ngModel)]="drawerValue">
                <ng-template let-item>
                  <i [class]="item.icon"></i>
                </ng-template>
              </p-selectButton>
              <p-button [pTooltip]="getSignOffTooltip()" icon="pi pi-pencil" tooltipPosition="top" label="Sign Off" [disabled]="!isSignOffAllowed || signoffdisabled" style="position: relative;top: 0px; line-height: 12px; margin-right: -10px" styleClass="p-button-raised p-button-text me-2 ms-2" (click)="openSignoffPanel()">
                <!--<i class="pi pi-pencil" style="font-size:0.7rem"></i>-->
              </p-button>
              <p-button tooltipPosition="top" icon="pi pi-delete-left" label="Remove Sign Off" [disabled]="!isRemoveSignOffAllowed || removesignoffdisabled" style="position: relative;top: 0px; line-height: 12px; margin-right: -10px" styleClass="p-button-raised p-button-text p-button-danger me-2 ms-2" (click)="openRemoveSignoffPanel()">
                <!--<i class="pi pi-delete-left" style="font-size:0.7rem"></i>-->
              </p-button>
              <p-button label="Send to Global Portal" icon="pi pi-send" style="position: relative;top: 0px; line-height: 12px;" styleClass="p-button-raised p-button-text me-2 ms-2" (click)="sendToGlobalPortal()" [disabled]="!isSentToGPAllowed || sendToGPDisabled">
                <!--<i class="pi pi-send" style="font-size:0.7rem"></i>-->
              </p-button>
              <p-button label="Download Document(s)" icon="pi pi-download" style="position: relative; top: 0px; line-height: 12px; margin-left: -10px"
                        styleClass="p-button-raised p-button-text me-2 ms-2" (click)="onDownloadDocumentsButtonClick()" [disabled]="downloadDisabled">
              </p-button>
              <p-button label="Open in browser" icon="pi pi-external-link" style="position: relative;top: 0px; line-height: 12px; margin-left: -10px"
                        styleClass="p-button-raised p-button-text me-2 ms-2" (click)="openDocsInBrowser()" [disabled]="openInBrowserDisabled">
                <!--<i class="pi pi-external-link" style="font-size:0.7rem"></i>-->
              </p-button>
              <p-button label="Save" icon="pi pi-save" style="position: relative;top: 0px; line-height: 12px; margin-left: -10px"
                        styleClass="p-button-raised p-button-text me-2 ms-2" (click)="saveGridInformation()" [disabled]="saveDisabled">
                <!--<i class="pi pi-external-link" style="font-size:0.7rem"></i>-->
              </p-button>
              <p-toast></p-toast>
            </div>
          </div>
        </div>
        <div class="col text-end">
          <p-button label="Refresh" styleClass="p-button-text" icon="pi pi-refresh" style="margin-right:5px" (click)="reloadSignOff()">
            <!--<i class="pi pi-refresh" style="font-size:0.7rem"></i>-->
          </p-button>
          <button style="margin-right:5px" mat-flat-button (click)="deleteRows()" [disabled]="!isDeleteAllowed || deletedisabled">
            <i class="pi pi-trash" style="font-size:0.7rem"></i>
            Delete
          </button>
          <button mat-flat-button style="margin-right:5px" (click)="goToRecoverScreen()" [disabled]="!isRecoverAllowed || recoverDisabled">
            <i class="pi pi-undo" style="font-size:0.7rem"></i>
            Recover
          </button>
          <p-button [pTooltip]="getEditTooltip()"
                    tooltipPosition="top"
                    icon="pi pi-pencil"
                    label="Edit"
                    [disabled]="!isEditMetadataAllowed || editDisabled"
                    styleClass="p-button-text"
                    (click)="openEditMetadataSidebar()"
                    [ngClass]="{'buttonDisabledShadow' : editDisabled, '' : !editDisabled}">
            <!--<i class="pi pi-pencil" style="font-size:0.7rem"></i>-->
          </p-button>
          <p-splitButton #ua label="More" (onClick)="ua.onDropdownButtonClick($event)" styleClass="splitbuttonCustom p-button-sm p-button-text p-button-plain" icon="pi pi-ellipsis-v" [model]="items" [disabled]="moreMenuDisabled">
          </p-splitButton>
        </div>
      </div>

      <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

      <app-matrix [saveGridInformation]="saveRequest" [isEditMetadataAllowed]="isEditMetadataAllowed" [isApplicableTaxYearRequired]="isApplicableTaxYearRequired" (saveCompleteEvent)="saveGridInformation($event)" (documentUddUpdateEvent)="checkUddUpdateDocuments($event)"></app-matrix>
    </ng-template>
  </p-fileUpload>
</div>


<!--File Upload Sidebar-->

<div class="card flex justify-content-center">
  <p-sidebar [(visible)]="fileUploadSidebarVisible" [dismissible]="false" [blockScroll]="true" (onHide)="onCloseSidebar('upload')" position="right" styleClass="w-30rem">

    <ng-template pTemplate="header">
      <h6 class="h6">
        Upload Document(s)
      </h6>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="">
        <p-fileUpload #fileUpload [files]="files" draggable="true"
                      [showUploadButton]="false" [showCancelButton]="true"
                      [multiple]="true" [maxFileSize]="2.0000E+9" [fileLimit]="10"
                      [customUpload]="true" chooseLabel="Choose documents"
                      (onSelect)="onSelectFile($event)" (onClear)="onCancel($event)"
                      emptyTemplate="No files selected">

          <!--<ng-template pTemplate="toolbar">
            <div class="py-1"><p class="text-muted">Upload maximum 10 files</p></div>
          </ng-template>-->

          <ng-template let-file pTemplate='file'>
            <div>
              <div #filediv class="card p-1 mb-1 files" (click)="onfileclick(filediv)">
                <div class="card-text text-justify file-name-container">
                  <i [ngClass]="getFileClass(file.name)"
                     style="font-size:1rem; position:relative; top:2px;">
                  </i>
                  <span class="truncate" [pTooltip]="file.name" tooltipPosition="left">{{file.name}}</span>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template pTemplate="content" let-files>
            <div *ngIf="uploadedFiles.length === 0" style="align-content:center">Drop files here</div>
          </ng-template>

        </p-fileUpload>
      </div>

      <div class="mt-3">
        <!--  <form>-->
        <div class="form-text mb-2">
          <h6 class="h6">Select Documents Details</h6>
        </div>
        <div>
          <div><strong>Client : </strong> {{client}}</div>
          <div><strong>Taxpayer : </strong> {{taxpayer}}</div>
          <!--<div><strong>Year : </strong> {{year}}</div>-->
          <div *ngIf="engagementTaxYear != undefined"><strong>Engagement Tax Year : </strong> {{engagementTaxYear}}</div>
          <div *ngIf="workflowTaxYear !== undefined && workflowTaxYear !== null && workflowTaxYear !== ''"><strong>Workflow Tax Year : </strong> {{workflowTaxYear}}</div>
          <div *ngIf="engagement != null"><strong>Engagement : </strong> {{engagement}}</div>
          <div *ngIf="openWorkflowPopup"></div>
          <div *ngIf="!openWorkflowPopup && workflow !== undefined && workflow !== null && workflow !== ''">
            <strong>Workflow : </strong> {{workflow}}
          </div>
        </div>

        <!-- Workflow Popup -->
        <div *ngIf="openWorkflowPopup && !isApplicableTaxYearRequired">
          <label>Select Related Workflow<span *ngIf="!workflowDropdownOptionalAndReadonly" class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="workflowDD" class="promptWorkflowBorder"
                        [disabled]="workflowdropdowndisabled || uploadedFiles.length === 0"
                        [style]="{'width':'100%'}" [(ngModel)]="workflowselectedvalue"
                        (onChange)="metadataChanged($event, 'workflow')"
                        placeholder="Select the related workflow" optionLabel="name" [required]="true"></p-dropdown>
          </div>
        </div>

        <div>
          <label>Document Type Group<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="docTypeGroupDD" [disabled]="doctypegroupdropdowndisabled || uploadedFiles.length === 0 || isatdoctypegroupnode"
                        [style]="{'width':'100%'}" [(ngModel)]="doctypegroupddselectedvalue"
                        (onChange)="metadataChanged($event, 'doctypegroup')"
                        placeholder="Select a document type group" optionLabel="name" [required]="true"></p-dropdown>
          </div>
        </div>

        <div class="mb-2">
          <label>Document Type<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown #doctype [options]="docTypeDD" [disabled]="doctypedropdowndisabled || uploadedFiles.length === 0 || isatdoctypenode"
                        [style]="{'width':'100%', 'hieght':'35px'}" [(ngModel)]="doctypeddselectedvalue"
                        (onChange)="metadataChanged($event, 'doctype')"
                        placeholder="Select a document type" optionLabel="name" [required]="true"></p-dropdown>
          </div>
        </div>

        <div class="mb-2">
          <label>Document Description<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="docDescriptionDD" [disabled]="docdescriptiondropdowndisabled || uploadedFiles.length === 0"
                        [style]="{'width':'100%'}" [(ngModel)]="docdescriptionddselectedvalue"
                        (onChange)="metadataChanged($event, 'docdescription')"
                        placeholder="Select a document description" optionLabel="name" [required]="true"></p-dropdown>
          </div>
        </div>
        <div *ngIf="isSecondaryDescriptionRequired" class="mb-2">
          <label>Secondary Description<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="secDescriptionDD" [disabled]="secdescriptiondropdowndisabled || uploadedFiles.length === 0"
                        [style]="{'width':'100%'}" [(ngModel)]="secdescriptionddselectedvalue"
                        (onChange)="metadataChanged($event, 'secdescription')"
                        placeholder="Select a secondary description" optionLabel="name" [required]="true"></p-dropdown>
          </div>
        </div>
        <div *ngIf="!isSecondaryDescriptionRequired" class="mb-2">
          <label>Secondary Description</label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="secDescriptionDD" [disabled]="secdescriptiondropdowndisabled || uploadedFiles.length === 0"
                        [style]="{'width':'100%'}" [(ngModel)]="secdescriptionddselectedvalue"
                        (onChange)="metadataChanged($event, 'secdescription')"
                        placeholder="Select a secondary description" optionLabel="name" [required]="false"></p-dropdown>
          </div>
        </div>
        <div class="mb-2">
          <label>User Defined Description</label>
          <div class="card flex justify-content-center">
            <input type="text" pInputText [(ngModel)]="userdefineddescription" (ngModelChange)="metadataChanged($event, 'userdefineddescription')" placeholder="User defined description" />
          </div>
        </div>

        <div *ngIf="isAtTaxpayerLevel" class="mb-2">
          <label>Tax Year<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="years" [(ngModel)]="selectedTaxYear" placeholder="Select Tax Year"
                        [disabled]="taxyeardisabled || uploadedFiles.length === 0 || isattaxyearlevel"
                        [style]="{'width':'100%', 'hieght':'35px', 'margin-top':'5px'}" class="w-full"
                        [showClear]="true" optionLabel="name" (onChange)="metadataChanged($event, 'taxyear')">
            </p-dropdown>
          </div>
        </div>

        <div class="mb-2">
          <label>Applicable Tax Year<span *ngIf="isApplicableTaxYearRequired" class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-multiSelect [options]="years" placeholder="Select Years"
                           [style]="{'width':'100%', 'hieght':'35px', 'margin-top':'5px'}" class="w-full"
                           [(ngModel)]="selectedYears" (onChange)="metadataChanged($event, 'applicationtaxyear')"
                           [showClear]="false" optionLabel="name">
            </p-multiSelect>
          </div>
        </div>

        <div *ngIf="isWorkflowActive || (openWorkflowPopup && !isApplicableTaxYearRequired)" class="mb-2">
          <label>Rollforward Status</label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="yesNoDD" [disabled]="uploadedFiles.length === 0"
                        [style]="{'width':'100%'}" [(ngModel)]="rollforwardselectedvalue"
                        (onChange)="metadataChanged($event, 'rollforward')"
                        placeholder="Rollforward" optionLabel="name" [required]="false"></p-dropdown>
          </div>
        </div>

        <div *ngIf="this.uploadedFiles.length > 1 && this.selectedFileName" class="mb-4">
          <label>Do you want to apply the selected metadata to all documents?</label>
          <div class="flex justify-content-center">
            <input type="checkbox" value="copymetadata" (change)="copyMetadataToUploadDocuments()" placeholder="Copy Metadata to all documents for upload" title="Please enable the checkbox to copy metadata to call documents" />
          </div>
        </div>

        <div class="">
          <p-button label="Upload document(s)" icon="pi pi-upload" styleClass="p-button-sm"
                    [disabled]="uploadValidation()"
                    (click)="uploadDocuments()"></p-button>

          <p-progressSpinner *ngIf="isUploading" styleClass="ms-2 w-35px h-35px" strokeWidth="6" fill="var(--surface-0)" animationDuration=".5s"></p-progressSpinner>

        </div>
        <!-- </form>-->
      </div>

    </ng-template>

  </p-sidebar>
</div>

<!--Sign Off Sidebar-->
<div class="card flex justify-content-center">
  <p-sidebar [(visible)]="signoffSidebarVisible" [blockScroll]="true" (onHide)="onCloseSidebar('signoff')" position="right" styleClass="w-30rem">
    <ng-template pTemplate="header">
      <h6 class="h6">
        Sign Off Row(s)
      </h6>
    </ng-template>

    <ng-template pTemplate="content">

      <div class="mt-2">


        <div class="mb-2">
          <label><h5>Role<span class="required-field">*</span></h5></label>
          <div class="card flex justify-content-center">
            <p-dropdown #role [options]="newRoleDD" [style]="{'width':'100%', 'hieght':'35px'}"
                        [(ngModel)]="roleselectedvalue"
                        placeholder="Select a role" optionLabel="name">
            </p-dropdown>
          </div>
        </div>

        <div class="">
          <p-button label="Save" icon="pi pi-save" [disabled]="!roleselectedvalue" styleClass="p-button-sm" (click)=" signoffRows('')"></p-button>
        </div>

      </div>

    </ng-template>

  </p-sidebar>
</div>

<!--Remove Sign Off Sidebar-->
<div class="card flex justify-content-center">
  <p-sidebar [(visible)]="removeSignoffSidebarVisible" [blockScroll]="true" (onHide)="onCloseSidebar('removesignoff')" position="right" styleClass="w-30rem">
    <ng-template pTemplate="header">
      <h6 class="h6">
        Remove Sign Off
      </h6>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="mt-2">
        <div class="mb-2">
          <label>Role<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown #role [options]="removeRoleDD" [style]="{'width':'100%', 'hieght':'35px'}"
                        [(ngModel)]="removeRoleSelectedValue"
                        placeholder="Select a role" optionLabel="name">
            </p-dropdown>
          </div>
        </div>

        <div class="">
          <p-button label="Remove" icon="pi pi-trash" [disabled]="!removeRoleSelectedValue" styleClass="p-button-sm p-button-danger" (click)="removeSignoffRows('')"></p-button>
        </div>

      </div>

    </ng-template>

  </p-sidebar>
</div>

<!--Editmetadata Sidebar-->
<div class="card flex justify-content-center">
  <p-sidebar position="right" [(visible)]="IsEditSidebarVisible" [blockScroll]="true" (onHide)="onCloseSidebar('edit')" styleClass="w-30rem">
    <ng-template pTemplate="header">
      <h6 class="h6">
        Edit metadata
      </h6>
    </ng-template>

    <ng-template pTemplate="content">

      <div class="mt-2">

        <div class="mb-2">
          <label>Taxpayer<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="getMetadata.taxpayers"
                        [style]="{'width':'100%'}"
                        [(ngModel)]="getMetadata.selectedTaxpayer"
                        (ngModelChange)="metadataChangedForEdit($event, 'taxpayer')"
                        placeholder="Select a taxpayer"
                        optionLabel="name"
                        [required]="true"
                        [disabled]="selectedDocuments[0].isAssociated === true">
            </p-dropdown>
          </div>
        </div>
        <div *ngIf="showUserPrompt" class="mb-2">
          <label>Do you want to update the metadata at Taxpayer Level?<span class="required-field">*</span></label>
          <div class="row">
            <div class="col-2">
              <input type="radio"
                     name="yes"
                     value="yes"
                     checked
                     [disabled]="selectedDocuments[0].isAssociated === true"
                     (click)="disableOrEnableEngagementAndWorkflow(true, getMetadata.selectedTaxpayer, false)" />
                Yes
            </div>
            <div class="col-2">
              <input type="radio"
                     name="yes"
                     value="no"
                     [disabled]="selectedDocuments[0].isAssociated === true"
                     (click)="disableOrEnableEngagementAndWorkflow(false, getMetadata.selectedTaxpayer, false)" />
                No
            </div>
          </div>
        </div>
        <div *ngIf="showTaxpayerReindexPrompt" class="mb-2">
          <label>Do you want to reindex the Taxpayer?<span class="required-field">*</span></label>
          <div class="row">
            <div class="col-2">
              <input type="radio"
                     name="yes"
                     value="yes"
                     [disabled]="selectedDocuments[0].isAssociated === true"
                     (click)="disableOrEnableEngagementAndWorkflow(true, getMetadata.selectedTaxpayer, true)" />
              Yes
            </div>
            <div class="col-2">
              <input type="radio"
                     name="yes"
                     value="no"
                     checked
                     [disabled]="selectedDocuments[0].isAssociated === true"
                     (click)="disableOrEnableEngagementAndWorkflow(false, getMetadata.selectedTaxpayer, true)" />
              No
            </div>
          </div>
        </div>
      
        <div class="mb-2">
          <label>Engagement<span *ngIf="!disableEngagement" class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="getMetadata.engagements"
                        [style]="{'width':'100%'}"
                        [(ngModel)]="getMetadata.selectedEngagement"
                        (ngModelChange)="metadataChangedForEdit($event, 'engagement')"
                        placeholder="Select a engagement"
                        optionLabel="name"
                        [required]="!disableEngagement"
                        [disabled]="disableEngagement || selectedDocuments[0].isAssociated === true">

            </p-dropdown>
          </div>
        </div>

        <div *ngIf="!workflowDropdownOptionalAndReadonly" class="mb-2">
          <label>Workflow<span *ngIf="!disableWorkflow" class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="getMetadata.workflows"
                        [ngClass]="{'mock-dirty' : !disableWorkflow && !getMetadata.selectedWorkflow}"
                        [style]="{'width':'100%'}"
                        [(ngModel)]="getMetadata.selectedWorkflow"
                        (ngModelChange)="metadataChangedForEdit($event, 'workflow')"
                        placeholder="Select a workflow"
                        optionLabel="name"
                        [required]="!disableWorkflow"
                        [disabled]="disableWorkflow || selectedDocuments[0].isAssociated === true">

            </p-dropdown>
          </div>
        </div>

        <div class="mb-2">
          <label>Document Type Group<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="getMetadata.doctypeGroups"
                        [style]="{'width':'100%'}"
                        [(ngModel)]="getMetadata.selectedDoctypeGroup"
                        (ngModelChange)="metadataChangedForEdit($event, 'doctypegroup')"
                        placeholder="Select a document type group"
                        optionLabel="name"                        
                        [required]="true"
                        [disabled]="selectedDocuments[0].isAssociated === true">
            </p-dropdown>
          </div>
        </div>

        <div class="mb-2">
          <label>Document Type<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown #doctype [options]="getMetadata.doctypes"
                        [style]="{'width':'100%', 'hieght':'35px'}" [(ngModel)]="getMetadata.selectedDoctype"
                        (ngModelChange)="metadataChangedForEdit($event, 'doctype')"
                        placeholder="Select a document type" optionLabel="name" [required]="true"></p-dropdown>
          </div>
        </div>

        <div class="mb-2">
          <label>Document Description<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="getMetadata.doctypeDescs"
                        [style]="{'width':'100%'}" [(ngModel)]="getMetadata.selectedDoctypeDesc"
                        (ngModelChange)="metadataChangedForEdit($event, 'docdescription')"
                        placeholder="Select a document description" optionLabel="name" [required]="true"></p-dropdown>
          </div>
        </div>
        <div *ngIf="isEditSecondaryDescriptionRequired" class="mb-2">
          <label>Secondary Description<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="getMetadata.doctype2ndDescs"
                        [style]="{'width':'100%'}" [(ngModel)]="getMetadata.selectedDoctype2ndDesc"
                        (ngModelChange)="metadataChangedForEdit($event, 'secdescription')"
                        placeholder="Select a secondary description" optionLabel="name" [required]="true"></p-dropdown>
          </div>
        </div>
        <div *ngIf="!isEditSecondaryDescriptionRequired" class="mb-2">
          <label>Secondary Description</label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="getMetadata.doctype2ndDescs"
                        [style]="{'width':'100%'}" [(ngModel)]="getMetadata.selectedDoctype2ndDesc"
                        (ngModelChange)="metadataChangedForEdit($event, 'secdescription')"
                        placeholder="Select a secondary description" optionLabel="name" [required]="false"></p-dropdown>
          </div>
        </div>

        <div class="mb-2">
          <label>User Defined Description</label>
          <div class="card flex justify-content-center">
            <input type="text" pInputText [(ngModel)]="getMetadata.userDefineDescription"
                   (ngModelChange)="metadataChangedForEdit($event, 'userdefineddescription')" placeholder="User defined description" />
          </div>
        </div>

        <div *ngIf="!workflowDropdownOptionalAndReadonly" class="mb-2">
          <label>Tax Year<span class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-dropdown [options]="years" [(ngModel)]="selectedTaxYearForEdit" placeholder="Tax Year"
                        [disabled]="taxyeardisabled"
                        [style]="{'width':'100%', 'hieght':'35px', 'margin-top':'5px'}" class="w-full"
                        [showClear]="true" optionLabel="name" (onChange)="metadataChangedForEdit($event, 'taxyear')">
            </p-dropdown>
          </div>
        </div>

        <div class="mb-2">
          <label>Applicable Tax Year<span *ngIf="isApplicableTaxYearRequired" class="required-field">*</span></label>
          <div class="card flex justify-content-center">
            <p-multiSelect [options]="years" [(ngModel)]="selectedYearsForEdit" placeholder="Applicable Tax Year"
                           [style]="{'width':'100%', 'hieght':'35px', 'margin-top':'5px'}" class="w-full"
                           (onChange)="metadataChangedForEdit($event, 'applicabletaxyear')"
                           [showClear]="false" optionLabel="name">
            </p-multiSelect>
          </div>
        </div>

        <div class="">
          <p-button label="Update" icon="pi pi-save" styleClass="p-button-sm"
                    [disabled]="updateValidation()"
                    (click)="updateDocuments()"></p-button>

          <p-progressSpinner *ngIf="isUpdating" styleClass="ms-2 w-35px h-35px" strokeWidth="6" fill="var(--surface-0)" animationDuration=".5s"></p-progressSpinner>
        </div>
      </div>
    </ng-template>
  </p-sidebar>
</div>

<!--Tag Document SideBar-->
<div class="card flex justify-content-center">
  <p-sidebar [(visible)]="tagDocumentSideBarVisible"
             [blockScroll]="true"
             (onHide)="onCloseSidebar('tagdocument')"
             position="right"
             styleClass="w-30rem">
    <ng-template pTemplate="header">
      <h6 class="h6">
        Tag Document: Add Additional Metadata
      </h6>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-2">
        <div class="mb-4">
          <label><h5>{{TagLabel}}</h5></label>
          <div class="card flex justify-content-center">
            <p-multiSelect [options]="tagDocumentDD"
                           [(ngModel)]="selectedTags"
                           placeholder="{{TagPlaceholder}}"
                           optionLabel="name">
              <ng-template let-item pTemplate="item">
                <div class="dropdown-item">
                  <i class="pi pi-circle-fill"
                     [ngClass]="item.isActive === true ? 'pi-circle-fill-active' : 'pi-circle-fill-inactive'"></i>
                  {{item.name}}
                </div>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
        <div class="">
          <p-button label="Save"
                    [disabled]="isTagging"
                    icon="pi pi-save"
                    styleClass="p-button-sm"
                    (click)=" tagDocument()">
          </p-button>
          <p-progressSpinner *ngIf="isTagging"
                             styleClass="ms-2 w-35px h-35px"
                             strokeWidth="6"
                             fill="var(--surface-0)"
                             animationDuration=".5s"></p-progressSpinner>
        </div>
      </div>
    </ng-template>
  </p-sidebar>
</div>
