import { Component } from '@angular/core';
import { LocalService } from '../../services/local.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-time-out',
  templateUrl: './time-out.component.html'
})
export class TimeOutComponent {

  constructor(private localService: LocalService,
    private route: ActivatedRoute, private router: Router, private location: Location,
    private msalService: MsalService) {

  }

  public redirectToLogOut() {
    this.msalService.logoutRedirect();
  }
}
