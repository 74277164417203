<div class="modal-header">
    <h5 class="modal-title" id="conf_dialog_title">{{title}}</h5>
    <button *ngIf="!onlyShowOk" id="conf_dialog_close" class="close" [attr.aria-label]="DIALOG_CLOSE" (click)="dialogRef.close(DIALOG_CLOSE)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" id="conf_dialog_body" [innerHTML]="content"></div>
<div class="modal-footer">
    <div class="modal-footer-section">
        <button *ngIf="!onlyShowOk && !onlyShowYesNo" id="conf_dialog_cancel" cdkFocusInitial class="btn btn-primary" [attr.aria-label]="DIALOG_CANCEL" (click)="dialogRef.close(DIALOG_CANCEL)">{{DIALOG_CANCEL}}</button>
        <button *ngIf="debug" id="conf_dialog_debug" class="btn btn-danger" aria-label="Debug" (click)="downloadDebugData()">Download HTTP Error Data</button>
    </div>
    <div class="modal-footer-section">
        <button *ngIf="!onlyShowOk" id="conf_dialog_no" cdkFocusInitial class="btn btn-secondary" [attr.aria-label]="DIALOG_NO" (click)="dialogRef.close(DIALOG_NO);">{{DIALOG_NO}}</button>
        <button *ngIf="!onlyShowOk" id="conf_dialog_yes" class="btn btn-danger" [attr.aria-label]="DIALOG_YES" (click)="dialogRef.close(DIALOG_YES);">{{DIALOG_YES}}</button>

        <button *ngIf="onlyShowOk" id="conf_dialog_ok" cdkFocusInitial class="btn btn-primary" [attr.aria-label]="DIALOG_OK" (click)="dialogRef.close(DIALOG_OK);">{{DIALOG_OK}}</button>
    </div>
</div>
