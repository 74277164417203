import { Component, Inject, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, RedirectRequest } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { clearStorage } from '../services/storage-utils';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  loginDisplay = false;
  token: string | undefined;
  activeAccount!: any;
  constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) { }

  ngOnInit(): void {
    //this.msalBroadcastService.msalSubject$
    //  .pipe(
    //    filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    //  )
    //  .subscribe((result: EventMessage) => {
    //    const payload = result.payload as AuthenticationResult;
    //    this.authService.instance.setActiveAccount(payload.account);


    //  });

    //this.msalBroadcastService.inProgress$
    //  .pipe(
    //    filter((status: InteractionStatus) => status === InteractionStatus.None)
    //  )
    //  .subscribe(() => {
    //    //this.token = this.authService.instance.getActiveAccount()?.idToken;
    //    this.activeAccount = this.authService.instance.getActiveAccount();
    //    //console.log(this.activeAccount);
    //    //console.log(this.token);
    //  })
    this.activeAccount = this.authService?.instance?.getActiveAccount();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService?.instance?.getAllAccounts()?.length > 0;
  }

  login() {
    //if (this.msalGuardConfig.authRequest) {
    //  this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    //} else {
    //  this.authService.loginRedirect();
    //}
  }

  logout() {
    const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

    clearStorage(activeAccount); // clear the storage of any claim challenges

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
    //const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];
    //this.authService.logoutRedirect({
    //  account: activeAccount,
    //});
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
