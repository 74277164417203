import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalService } from '../services/local.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

import { Location } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class TimeOutService {

  private activityTimer$: Observable<number> | undefined;
  private activityTimerSubject = new Subject<number>();

  constructor(private router: Router, private localService: LocalService, private location: Location) {
  }

  startSessionTimer(): void {
    this.activityTimer$ = timer(environment.TIMEOUT_DURATION_MS);
    this.activityTimer$.pipe(takeUntil(this.activityTimerSubject))
      .subscribe(() => {
        this.router.navigate(['/timeout']); // Redirect to login page on timeout
      });
  }

  resetSessionTimer(): void {
    this.activityTimerSubject.next(0);
    this.startSessionTimer();
  }

  startMonitoringUserActivity(): void {
    window.addEventListener('mousemove', this.resetSessionTimer.bind(this));
    window.addEventListener('keydown', this.resetSessionTimer.bind(this));
    window.addEventListener('scroll', this.resetSessionTimer.bind(this));
    window.addEventListener('click', this.resetSessionTimer.bind(this));
    this.startSessionTimer();
  }
}
