import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogInput } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ServerSideConfigDataService } from '../services/server-side-config-data/server-side-config-data.service';
import * as moment from 'moment';

import { EntityError, HttpResponse } from "breeze-client";

interface InnerError {
    entityErrors: EntityError[];
    httpResponse: HttpResponse;
    message: string;
    stack?: string;
    status?: number;
}

export interface BreezeQueuedSaveFailedError {
    innerError: InnerError;
    message: string;
    failedSaveMemo: unknown;
    nextSaveMemo: unknown;
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private serverSideConfig: ServerSideConfigDataService;
    private dialog: MatDialog;

    constructor(private injector: Injector) {
        this.serverSideConfig = this.injector.get(ServerSideConfigDataService);
        this.dialog = this.injector.get(MatDialog);
    }

    private previousErrors: TrackedError[] = [];

    handleError(error: unknown): void {
        let showDialog = false;
        let message = "";
        let httpResponse = null;

        if (error instanceof HttpErrorResponse) { // Errors from HTTP Request
            showDialog = true;

            const http = this.parseHttpError(error);
            message = http.message;
            httpResponse = http.httpResponse;
        // eslint-disable-next-line
        } else if ((error as any).innerError) { // Errors from breeze
            showDialog = true;

            message = this.parseQueuedSaveFailedError(error as BreezeQueuedSaveFailedError);
        } else {
            // Assuming these will be typical javascript errors
            // User most likely does not need to be notified
            // of these so we are not showing the dialog, but
            // will still pass the error on to the console as
            // normal

            // Uncomment these lines if you would like to see
            // console errors in the form of popups:
            // showDialog = !this.errorAlreadyOccurred(e);
            // message = e.message;

            throw error;
        }

        if (showDialog) {
            this.dialog.open(ConfirmationDialogComponent, {
                disableClose: true,
                data: <ConfirmationDialogInput>{
                    title: 'An Error Has Occurred',
                    content: message,
                    onlyShowOk: true,
                    debug: httpResponse
                }
            });
        }
    }

    private parseHttpError(response: HttpErrorResponse): { message: string; httpResponse?: string; } {
        if (this.serverSideConfig?.configData
            && ['Local', 'Development'].indexOf(this.serverSideConfig.configData.environment) > -1
            && typeof response.error === 'string'
            && response.error.indexOf('<!DOCTYPE html>') > -1
        ) {
            const regex = new RegExp(/<div class="titleerror">(.*?)<\/div>/);
            const match = response.error.match(regex);

            return {
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                message: match?.[1] || "An Exception has occurred",
                httpResponse: response.error
            };
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { message: response.error };
    }

    private parseQueuedSaveFailedError(error: BreezeQueuedSaveFailedError): string {
        return error.innerError.entityErrors.map(e => e.errorMessage).join('\n');
    }

    // The following two methods can be used to avoid handling repeated errors.
    // If a binding is wrong or something is misconfigured, Angular like to
    // throw an infinite number of errors in the console. The code below is
    // really just meant to mitigate that incase you want to show a dialog with
    // said error(s)
    private removeOldErrors() {
        if (this.previousErrors.length) {
            const oldestErrorTimestamp = moment().add(-2, 'seconds');
            this.previousErrors = this.previousErrors.filter(e => e.timestamp.isSameOrAfter(oldestErrorTimestamp));
        }
    }

    private errorAlreadyOccurred(error: Error) {
        this.removeOldErrors();

        const prevError = this.previousErrors.filter(e => e.error.message === error.message)[0];
        const i = prevError ? this.previousErrors.indexOf(prevError) : -1;

        if (i > -1) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.previousErrors[i]!.timestamp = moment();
            return true;
        } else {
            this.previousErrors.push({
                error: error,
                timestamp: moment()
            });
            return false;
        }
    }
}

interface TrackedError {
    error: Error;
    timestamp: moment.Moment;
}
