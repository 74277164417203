import { APP_INITIALIZER, ErrorHandler, NgModule, importProvidersFrom, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withInterceptors } from '@angular/common/http';
import { RouterModule, provideRouter } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home/home.component';
import { TreeviewComponent } from './treeview/treeview.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialModule } from '../material.module';
import { MatrixComponent } from './matrix/matrix.component';
import { DocmanagementComponent } from './docmanagement/docmanagement.component';
import { TruncatePipe } from './pipe/truncatepipe';
import { TruncateTreeDataPipe } from './pipe/truncatetreedatapipe';
import { ChangeToInitials } from './pipe/changetoinitials';
import { FormatDate } from './pipe/formatdate';
import { TruncateStringPipe } from './pipe/truncatestring';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { TreeModule } from 'primeng/tree';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu'
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FilterService } from 'primeng/api';
import { ContextMissingComponent } from './error-pages/context-missing/context-missing.component';
import { InternalServerComponent } from './error-pages/internal-server/internal-server.component';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { BrowserCacheLocation, Configuration, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { RecoverComponent } from './recover/recover.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { getClaimsFromStorage } from './services/storage-utils';
import { ToastrModule } from 'ngx-toastr';
import Config from "./services/config";
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatDialogModule } from '@angular/material/dialog';
import { GlobalErrorHandler } from './services/global-error-handler';
import { RequireAuthenticatedUserGuard } from './guards/auth/require-authenticated-user.guard';
import { UnitOfWorkService } from './services/unit-of-work/unit-of-work.service';
import { NetworkWatcherService } from './services/network-watcher/network-watcher.service';
import { ServerSideConfigDataService } from './services/server-side-config-data/server-side-config-data.service';
import { EntityManagerProviderService } from './services/entity-manager-provider/entity-manager-provider.service';
import { ApplicationInsightsService } from './services/application-insights/application-insights.service';
import { BridgeService } from './services/bridge/bridge.service';
import { IsBusyService } from './services/is-busy/is-busy.service';
import invariant from './services/tiny-invariant';
import { CommonService } from './services/common/common.service';
import { AppRoutingModule } from './routes';
import { TimeOutComponent } from './error-pages/time-out/time-out.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId, // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/' + environment.tenantId, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: environment.redirectUrl, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Points to window.location.origin by default.
    clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}

export const protectedResources = {
  profileApi: {
    endpoint: environment.baseUrl,
    scopes: ["api://" + environment.apiClientId + "/.default"]
  }
}

export const loginRequest = {
  scopes: []
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.profileApi.endpoint, [...protectedResources.profileApi.scopes]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: (msalService, httpReq, originalAuthRequest) => {
      const resource = new URL(httpReq.url).hostname;
      let claim =
        msalService?.instance?.getActiveAccount() &&
          getClaimsFromStorage(
            `cc.${msalConfig.auth.clientId}.${msalService?.instance?.getActiveAccount()?.idTokenClaims?.oid
            }.${resource}`
          )
          ? window.atob(
            getClaimsFromStorage(
              `cc.${msalConfig.auth.clientId}.${msalService?.instance?.getActiveAccount()?.idTokenClaims?.oid
              }.${resource}`
            )
          )
          : undefined;
      return {
        ...originalAuthRequest,
        claims: claim,
      };
    },
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}


export function getBaseUrl() {
  const el = document.getElementsByTagName("base")[0];
  invariant(el);

  return el.href;
}


export function init() {
  return () => {
    Config.init();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    TreeviewComponent,
    MatrixComponent,
    TruncatePipe,
    TruncateTreeDataPipe,
    TruncateStringPipe,
    ChangeToInitials,
    FormatDate,
    DocmanagementComponent,
    RecoverComponent,
    TimeOutComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    SidebarModule,
    FileUploadModule,
    DropdownModule,
    ToastModule,
    ButtonModule,
    FormsModule,
    MatNativeDateModule,
    MaterialModule,
    TreeModule,
    PaginatorModule,
    TableModule,
    CheckboxModule,
    SplitButtonModule,
    MenuModule,
    BreadcrumbModule,
    TooltipModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    InputTextModule,
    InputNumberModule,
    ConfirmDialogModule,
    MsalModule,
    //MsalModule.forRoot(
    //  new PublicClientApplication({
    //    auth: {
    //      clientId: environment.clientId,
    //      authority: "https://login.microsoftonline.com/" + environment.tenantId,
    //      redirectUri: environment.redirectUrl,
    //      clientCapabilities: ['CP1']
    //    },
    //    cache: {
    //      cacheLocation: "localStorage",
    //      storeAuthStateInCookie: isIE,
    //    },
    //  }),
    //  {
    //    interactionType: InteractionType.Redirect,
    //    authRequest:
    //    {
    //      scopes: [],
    //    },
    //  },
    //  {
    //    interactionType: InteractionType.Redirect,
    //    protectedResourceMap:
    //      new Map([
    //        // ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
    //        //  [environment.baseUrl, ['user.read']],
    //        [protectedResources.profileApi.endpoint, [...protectedResources.profileApi.scopes]]
    //      ]),
    //    authRequest: (msalService, httpReq, originalAuthRequest) => {
    //      const resource = new URL(httpReq.url).hostname;
    //      let claim =
    //        msalService.instance.getActiveAccount() &&
    //          getClaimsFromStorage(
    //            `cc.${environment.clientId}.${msalService.instance.getActiveAccount()?.idTokenClaims?.oid
    //            }.${resource}`
    //          )
    //          ? window.atob(
    //            getClaimsFromStorage(
    //              `cc.${environment.clientId}.${msalService.instance.getActiveAccount()?.idTokenClaims?.oid
    //              }.${resource}`
    //            )
    //          )
    //          : undefined;
    //      return {
    //        ...originalAuthRequest,
    //        claims: claim,
    //      };
    //    },
    //  }
    //),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [MsalGuard] },
      { path: 'auth', component: MsalRedirectComponent },
      { path: 'contexterror', component: ContextMissingComponent },
      { path: 'internalerror', component: InternalServerComponent },
      { path: 'timeout', component: TimeOutComponent },
      // { path : 'docmanagement',component: DocmanagementComponent},
      // { path: 'recover', component: RecoverComponent, canActivate: [MsalGuard] }
    ]),
    AppRoutingModule,
  ],
  providers: [
    { provide: "BASE_URL", useFactory: getBaseUrl, deps: [] },
    provideAnimations(),
    ApplicationInsightsService,
    BridgeService,
    EntityManagerProviderService,
    IsBusyService,
    NetworkWatcherService,
    ServerSideConfigDataService,
    UnitOfWorkService,
    GlobalErrorHandler,
    RequireAuthenticatedUserGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    importProvidersFrom(
      MatDialogModule,
      ToastrModule.forRoot({
        timeOut: Config.toastrTimeoutMs,
        positionClass: "toast-bottom-right",
        preventDuplicates: true,
        countDuplicates: true,
      }),
      // ServiceWorkerModule.register("ngsw-worker.js", {
      // 	enabled: !isDevMode(),
      // 	// Register the ServiceWorker as soon as the application is stable
      // 	// or after 30 seconds (whichever comes first).
      // 	registrationStrategy: "registerWhenStable:30000",
      // })
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    FilterService,
    ConfirmationService,
    CommonService,
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([])),
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})

export class AppModule {
}
