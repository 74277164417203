<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Session Expired</title>
  <style>
    body {
      background-color: #222;
      color: #fff;
      font-family: sans-serif;
      margin: 0;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
    }

    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;
    }

    a {
      color: #337ab7;
      text-decoration: none;
    }

      a:hover {
        text-decoration: underline;
      }
  </style>
</head>
<body>
  <h1>Session Expired</h1>
  <p>
    Your session has expired due to inactivity. For your security, we have ended your session. Please click the <b>Logout</b> button below to securely log out of all sessions.
    We appreciate your understanding and apologize for any inconvenience. Thank you for helping us maintain the security of your account.
  </p>
  <button mat-flat-button style="margin-right:5px" (click)="redirectToLogOut()"> Logout</button>
</body>
</html>
