import { Pipe, PipeTransform } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { CustomTreeNode } from '../treeview/CustomTreeNode';

@Pipe({
  name: 'truncatetreedata'
})

export class TruncateTreeDataPipe implements PipeTransform {
  
  transform(data: CustomTreeNode[]): TreeNode[] {
    let count;
    for (count = 0; count < data.length; count++) {
      let dataItem = data[count];
      if (dataItem === undefined) continue;

      if (dataItem.fullLabel === undefined) { dataItem.fullLabel = dataItem.label; }

      let labelLength = data[count]?.label?.length ?? -1;
      if (labelLength > 30) {
        dataItem.label = dataItem.label?.slice(0, 30) + '...';
      }
      if (dataItem.children != null) {
        this.transform(dataItem.children);
      }
    }
    return data;
  }
}
