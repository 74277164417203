import { Component } from '@angular/core';

@Component({
  selector: 'app-context-missing',
  templateUrl: './context-missing.component.html',
  styleUrls: ['./context-missing.component.css']
})
export class ContextMissingComponent {

}
