enum TreeTypes {
  client,
  taxpayer,
  engagement,
  workflow,
  type,
  doctypegroup,
  doctype,
  docdesc,
  docsecdesc,
  engagementtaxyear,
  workflowtaxyear
}

export { TreeTypes };
