<div class="app-shell">
  <!-- <app-header></app-header> -->
  <app-nav-menu></app-nav-menu>
  <!-- <div class="app-content-shell">
      <router-outlet></router-outlet>
  </div> -->
  <div id="loading-bar-dots" class="loading-bar-dots loading-data">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>

  <div class="container-fluid">
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
  <!-- <div class="footer">
      <span>&copy; Copyright BDO USA, P.C, a Virginia professional corporation {{ currentYear }}. All Rights Reserved.</span>
  </div> -->
</div>

