<button mat-flat-button (click)="goToMainScreen()">
  <i class="pi pi-arrow-left" style="font-size:0.7rem"></i>
  Go back
</button>

<div class="row m-2">
  <div class="col-6">
    <h3>Recycle Bin</h3>
  </div>
  <div class="col-6 text-end">
    <p-toast></p-toast>
    <button mat-flat-button [disabled]="recoverdisabled" (click)="recoverRows()">
      <i class="pi pi-refresh" style="font-size:0.7rem"></i>
      Recover document(s)
    </button>
  </div>
</div>

<div class="row m-2">
  <h5 style="font-size:0.8rem">Client Name : {{clientName}}</h5>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<!--Document Type, Description, Secondary Description, User Defined Description, Tax Year, Taxpayer, Engagement, Workflow, Date & Time Deleted-->

<p-table #dt1
         [value]="deletedDocuments"
         [(selection)]="selectedDocuments"
         dataKey="key"
         [paginator]="true"
         [rows]="50"
         [showCurrentPageReport]="true"
         [globalFilterFields]="['doctype', 'doctypedesc', 'doctype2nddesc', 'userdefineddescription', 'year', 'taxpayer', 'engagement','workflow', 'deletedon']"
         [tableStyle]="{ 'min-width': '110rem' }"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} documents"
         [rowsPerPageOptions]="[50, 100, 200,500]"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowUnselect($event)"
         (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
         [metaKeySelection]="false"
         [loading]="isDeleteMatrixDataLoading"
         styleClass="p-datatable-sm">


  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="doctype" style="width:100px">Document Type<p-sortIcon field="doctype"></p-sortIcon></th>
      <th pSortableColumn="doctypedesc" style="width:100px">Description<p-sortIcon field="doctypedesc"></p-sortIcon></th>
      <th pSortableColumn="doctype2nddesc" style="width:100px">Secondary Description<p-sortIcon field="doctype2nddesc"></p-sortIcon></th>
      <th pSortableColumn="userdefineddescription" style="width:100px">User Defined Description<p-sortIcon field="userdefineddescription"></p-sortIcon></th>
      <th pSortableColumn="year" style="width:80px">Tax Year<p-sortIcon field="year"></p-sortIcon></th>
      <th pSortableColumn="taxpayer" style="width:80px">Taxpayer<p-sortIcon field="taxpayer"></p-sortIcon></th>
      <th pSortableColumn="engagement" style="width:80px">Engagement<p-sortIcon field="engagement"></p-sortIcon></th>
      <th pSortableColumn="workflow" style="width:80px">Workflow<p-sortIcon field="workflow"></p-sortIcon></th>
      <th pSortableColumn="deletedon" style="width:80px">Deleted On<p-sortIcon field="deletedon"></p-sortIcon></th>
    </tr>
    <tr>

      <th style="width: 20px">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th>
        <p-columnFilter type="text" field="doctype"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="doctypedesc"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="doctype2nddesc"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="userdefineddescription"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="year"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="taxpayer"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="engagement"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="workflow"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="deletedon"></p-columnFilter>
      </th>
    </tr>

  </ng-template>
  <ng-template pTemplate="body" let-document>
    <tr class="opacity-75">

      <td><p-tableCheckbox [value]="document"></p-tableCheckbox></td>

      <td>
        {{ document.doctype | truncatestring }}
      </td>

      <td>
        {{ document.doctypedesc | truncatestring }}
      </td>

      <td>
        {{ document.doctype2nddesc | truncatestring }}
      </td>

      <td>{{ document.userdefineddescription | truncatestring }}</td>

      <td>{{ document.year }}</td>
      <td>{{ document.taxpayer | truncatestring }}</td>
      <td>{{ document.engagement | truncatestring }}</td>
      <td>{{ document.workflow | truncatestring }}</td>

      <td>{{ document.deletedon | formatdate }}</td>

    </tr>
  </ng-template>
</p-table>


