export const messages = {
  archivedValidationError: "One or more document(s) selected are in archived status. Please try with documents which are not archived.",
  archivedValidationErrorSingleDoc: "The selected document is in archived status. Please try with document which is not archived.",
  holdDocumentsTriggered: "A message has been triggered to service bus to put document(s) on hold.",
  unholdDocumentsTriggered: "A message has been triggered to service bus to remove document(s) from hold.",
  holdDocuments: "Hold Document(s)",
  unholdDocuments: "Unhold Document(s)",
  uploadSizeExceeded: " can not be uploaded since it exceeded the maximum file size of 2 GB.",
  uploadfileCountExceeded: "Maximum number of files exceeded. Only 10 documents can be uploaded.",
  invalidFilename: " contains special characters. Please rename and try again.",
  fileUpload: "File Upload",
  multipleDocCheckoutError: "Please select only one document at a time to check out.",
  multipleDocCheckinError: "Please select only one document at a time to check in.",
  invalidDocCheckoutError: "The selected document is pending upload in SharePoint. Please select a document which is successfully uploaded to SharePoint.",
  checkOutDocument: "Check Out Document",
  checkInDocument: "Check In Document",
  checkOutSuccess: "The selected document is checked out successfully.",
  checkInSuccess: "The selected document is checked in successfully.",
  checkOutValidation: "The selected document is already checked out.",
  checkInValidation: "The selected document is already checked in.",
  checkOutFailure: "Document check out failed.",
  checkInFailure: "Document check in failed.",
  errorOccurred: "We're experiencing technical difficulties. Please try again later.",
  failedTreeView: "Oops! We encountered a problem fetching the document tree.",
  failedSignOffMatrix: "Oops! We encountered a problem fetching the sign off matrix.",
  failedUpload: "Oops! We encountered a problem while uploading the document(s).",
  failedSignOff: "Oops! We encountered a problem while signing off the document.",
  failedRemoveSignOff: "Oops! We encountered a problem while removing the sign off from the document.",
  failedDelete: "Oops! We encountered a problem while deleting the document(s).",
  failedRecovered: "Oops! We encountered a problem while recovering the document(s).",
  failedFetch: "Oops! We encountered a problem while fetching the record.",
  failedUpdate: "Oops! We encountered a problem while updating the record.",
  failedSearch: "Oops! We encountered a problem while searching.",
  invalidOperation: "An operation is already in-progress/error in upload for the selected document(s). Please select any other valid document(s) or try again later.",
  archiveDocuments: "Archive Document(s)",
  unarchiveDocuments: "Unarchive Document(s)",
  downloadDocumentError: "Oops! We encountered an error while downloading this document. Please try later.",
  downloadDocumentSuccess: "The document is successfully downloaded.",
  roleNotAssigned: "Roles not assigned for User",
  signOffWarning: "User must be assigned to a Role in order to sign off on a document",
  removeSignOffWarning: "User must be assigned to a Role in order to remove sign off on a document",
  selectWorkflowNode: "Documents should be selected from within a Workflow. Navigate to the Workflow in the Document Management Tree and then select documents for sign off.",
  selectDocument: "Please select a document for sign off",
  waitForDocUpload: "Please wait until the document is fully uploaded",
  successSignOff: "Signed Off successfully",
  successRemoveSignOff: "Removed signed off successfully",
  setRollforwardSuccess: "The selected document(s) have been successfully set to rollforward.",
  setRollforwardError: "Oops! We encountered a problem while setting rollforward flag.",
  rollForwardErrorWorkflowNotSetup:"The Document cannot be setup for Rollforward because the Workflow is not setup for Rollforward. Please update the Workflow to Rollforward first before marking Document for Rollforward.",
  resetRollforwardSuccess: "The selected document(s) have been successfully set to not rollforward.",
  resetRollforwardError: "Oops! We encountered a problem while resetting rollforward flag.",
  invalidRollforwardWarning: "Rollforward is only allowed on documents saved to a Workflow.",
  uploadNotAllowed: "Document(s) upload is not allowed.",
  updateSuccess: "Updated successfully.",
  zipFileWarning: "Zip File limit exceeded",
  zipFileMaxLimitExceededWarning: "The maximum number of selected files allowed in a zip download ([zipFileMaxFileCount]) has been exceeded. Please select fewer files.",
  zipFileDownloadBegin: "Generating Zip File for multiple file download. Your download will be available shortly and will automatically begin downloading."
}
