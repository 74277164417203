import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class RequireAuthenticatedUserGuard implements CanActivate {
    constructor(private msalService: MsalService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        const account = this.msalService.instance.getAllAccounts()[0];
        if (account) {
            return Promise.resolve(true);
        } else {
            return Promise.resolve(false);
        }
    }
}
