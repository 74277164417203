export class MetadataUpdate {
  clientId: string | undefined;
  documentId: string | undefined;
  taxpayerId: string | undefined;
  engagementId: string | undefined;
  workflowId: string | undefined;
  doctypeId: string | undefined;
  doctypeGroupId: string | undefined;
  doctypeDescId: string | undefined;
  doctype2ndDescId: string | undefined;
  userDefineDescription: string | undefined;
  applicableTaxYear: string | undefined;
  engagementTaxYear: string | undefined;
  taxYear: string | undefined;
  userEmailId: string | undefined;
}
