import { Injectable } from '@angular/core';
import { EntityManager, Validator } from 'breeze-client';
import { AnnotatedMetadata, MetaEntityType } from '../../../model/metaEntity';
import { environment } from '../../../environments/environment.prod';
import invariant from '../tiny-invariant';

@Injectable({
    providedIn: 'root'
})
export class BreezeValidatorsService {
    private entityManager: EntityManager | undefined;

    /**
     * Register your validators here
     * "EntityName": validatorMethod
     */
    private readonly validators: IDictionary = {
       // "Project": this.projectValidators
    };

    /**
     * These are custom validators. They will be called whenever saveChanges() or commit() is called.
     * This file might get pretty big, and you may want to consider breaking out each entity type
     * into it's own static file and reference them here
     */
    constructor() { }

    //private projectValidators(entityType: MetaEntityType): void {
    //    const nameProp = entityType.getProperty("name");
    //    if (!nameProp) {
    //        return;
    //    }

    //    nameProp.validators.push(new Validator(
    //        'uniqueRequirement',
    //        (value: string, context) => {
    //            invariant(this.entityManager);
    //            // eslint-disable-next-line
    //            const entity: Project = (<any>context).entity;

    //            if (!value) {
    //                return true;
    //            }

    //            const projects = <Project[]>this.entityManager.getEntities("Project");

    //            const isValid = projects
    //                .filter(p =>
    //                    p.id !== entity.id
    //                    && p.name
    //                    && p.name.trim().toLowerCase() === value.trim().toLowerCase()
    //                )
    //                .length === 0;

    //            return isValid;
    //        },
    //        {
    //            messageTemplate: `Project Name must be unique`
    //        }
    //    ));
    //}

    /**
     * Sets up the above validators. Called from EntityManagerProficerService during app initialization
     * @param entityTypes
     * @param entityManager
     */
    public setupCustomValidators(entityTypes: AnnotatedMetadata[], entityManager: EntityManager) {
        this.entityManager = entityManager;

        entityTypes.map(metaData => <MetaEntityType>entityManager.metadataStore.getEntityType(metaData.key, true))
            .forEach((entityType: MetaEntityType) => {
                const validator = this.validators[entityType.shortName];
                if (validator) {
                    (<ICustomValidator>validator.bind(this))(entityType);
                }
            }, this);
    }
}

type ICustomValidator = (entityType: MetaEntityType) => void;

interface IDictionary {
    [entity: string]: ICustomValidator;
}
