import { Component, Inject } from '@angular/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerSideConfigDataService } from '../../services/server-side-config-data/server-side-config-data.service';
import * as moment from 'moment';
import { DIALOG_CANCEL, DIALOG_CLOSE, DIALOG_NO, DIALOG_OK, DIALOG_YES } from './confirmation-dialog.constants';
import { NgIf } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.less'],
    imports: [
        NgIf,
        MatDialogModule,
    ],
})
export class ConfirmationDialogComponent {
    title = '';
    content = '';
    onlyShowOk = false;
    onlyShowYesNo = false;
    debug: string | undefined;

    // used in HTML
    get DIALOG_CANCEL() { return DIALOG_CANCEL; }
    get DIALOG_CLOSE() { return DIALOG_CLOSE; }
    get DIALOG_NO() { return DIALOG_NO; }
    get DIALOG_OK() { return DIALOG_OK; }
    get DIALOG_YES() { return DIALOG_YES; }

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ConfirmationDialogInput,
        serverSideConfig: ServerSideConfigDataService
    ) {
        this.dialogRef.disableClose = true;

        this.title = data.title;
        this.content = data.content;
        this.onlyShowOk = data.onlyShowOk || false;
        this.onlyShowYesNo = data.onlyShowYesNo || false;
        if (serverSideConfig && serverSideConfig.configData
            && ['Local', 'Development'].indexOf(serverSideConfig.configData.environment) > -1) {
            this.debug = data.debug;
        }
    }

    downloadDebugData(): void {
        if (this.debug) {
            const element = document.createElement('a');
            element.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.debug);
            element.download = `debug_${moment().format('MM-DD-YYYY_HH-mm-ss')}.html`;
            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }
}

export interface ConfirmationDialogInput {
    title: string;
    content: string;
    onlyShowOk?: boolean;
    onlyShowYesNo?: boolean;
    debug?: string;
}
